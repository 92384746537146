<template>
	<div class="p-md-4 p-3">
		<div class="SectionItem d-block no-b-b mb-4">
			<div class="flex__between">
				<p class="title m-0">Providers Settings</p>
				<div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
					<AddProviderButton :refetch="getProviders" />
				</div>
			</div>
			<p class="description">
				View all Infrastructure and Providers
			</p>
		</div>

		<div v-if="loading" class="flex__center">
			<div class="py-5 my-5">
				<Loader />
			</div>
		</div>

		<FailedState v-else-if="errorMsg" :errorMsg="errorMsg" :refetch="getProviders" />

		<div v-else>
			<template v-for="(provider, index) in providers">
				<div class="app-key-wrap accordion accordion-s2" :id="`provider${index}`" :key="index">
					<div class="app-key-item-wrapper accordion-item">
						<div class="app-key-list-item accordion-heading">
							<div class="service-list">
								<div class="service-name" data-toggle="collapse" :data-target="`#prov${index}`">
									<div class="service-icon">
										<em class="app-icon la la-cube"></em>
									</div>
									<span>
										{{ provider.provider_name }}
									</span>
								</div>
								<div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
									<ProviderActions :provider="provider" :apiServices="apiServices"
										:refetch="getProviders" :refetchSevice="getProviderParams" />
								</div>

								</div>
							</div>
							<div :id="`prov${index}`" class="collapse" :data-parent="`#provider${index}`" style>
								<ProviderParameters v-if="providersParams.length > 0" :provider="provider"
									:providersParams="providersParams" :refetch="getProviderParams" />
							</div>
						</div>
					</div>
			</template>
		</div>
	</div>
</template>

<script>
	import AddProviderButton from "../buttons/AddProviderButton/AddProviderButton";
	import ProviderActions from "../buttons/ProviderActions/ProviderActions";
	import FailedState from "../../../components/utils/ResponseState/FailedState";
	import ProviderParameters from "./ProviderParameters/ProviderParameters";

	export default {
		name: "ProvidersSettings",
		data() {
			return {
				loading: false,
				errorMsg: "",
				providers: [],
				providersParams: [],
				apiServices: [],
			};
		},
		components: {
			FailedState,
			AddProviderButton,
			ProviderActions,
			ProviderParameters,
		},
		methods: {
			async getProviders() {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						"infrastructureProviders/getInfrastructureProviders"
					);
					if (response.status_code === 200) {
						this.loading = false;
						this.providers = response.data;
					} else this.providers = [];
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
			async getProviderParams() {
				try {
					const response = await this.$store.dispatch(
						"infrastructureProviders/getProviderParameters"
					);
					if (response.status_code === 200)
						this.providersParams = response.data;
					else this.providersParams = [];
				} catch (error) {}
			},
			async getApiServices() {
				try {
					const response = await this.$store.dispatch(
						"infrastructureProviders/getAPIServices"
					);
					this.apiServices = response.data;
				} catch (error) {
					this.apiServices = [];
				}
			},
		},
		computed: {
			getUserRole() {
				return this.$store.state.account.profile.roles[0].role_label
			}	
		},
		mounted() {
			this.getProviderParams();
			this.getProviders();
			this.getApiServices();
		},
	};
</script>
