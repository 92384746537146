<template>
  <div class="p-md-4 p-3">
    <CreateInviteModal :show="showInviteModal" :toggle="toggleInviteModal" :invitesRef="$refs.invitesRef" />
    <InviteDetailModal :show="showSingleInviteModal" :toggle="toggleSingleInviteModal" :role="getUserRole"
      :singleInviteObj="singleInviteObj" />
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Customer Invites</p>
        <p class="text-left description">
          Request from new customers
        </p>
      </div>
    </div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="pageSelect">
          <div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
            <button @click="toggleInviteModal" class="btn btn-lg btn-primary">
              <span><img src="/assets/img/icons/sms.svg" alt="" /></span>
              Send Invite
            </button>
          </div>
        </template>
        <template slot="filterForm">
          <InvitesFilter :loading="loading" :rLoading="rLoading" :submitFilter="refetch"
            :clearFilter="() => clearFilter('invitesRef')" />
        </template>
      </FilterWrapper>
    </div>

    <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchCustomers" :isEmpty="isEmpty"
      :emptyTitle="emptyTitle">
      <div class="w-100 py-3" slot="content">
        <v-server-table ref="invitesRef" class="v-table row-pointer" :data="customers" :columns="columns"
          :options="options" @loaded="onLoaded" @row-click="handleClick">
          <div slot="is_active" slot-scope="{ row }">
            <span class="badge badge-app" :class="row.is_active == 1 ? 'badge-success' : 'badge-danger'">
              {{ row.is_active == 1 ? "Active" : "Inactive" }}
            </span>
          </div>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import InvitesFilter from "../../components/filters/InvitesFilter";
import CreateInviteModal from "./modals/CreateInviteModal";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import InviteDetailModal from "./modals/InviteDetailModal.vue";

const tableHeadings = {
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  is_active: "Status",
  created_at: "Date Created",
};

export default {
  name: "Customer",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Invite Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    InvitesFilter,
    CreateInviteModal,
    TableWrapper,
    InviteDetailModal,
  },
  methods: {
    toggleInviteModal() {
      this.showInviteModal = !this.showInviteModal;
    },
    toggleSingleInviteModal() {
      this.showSingleInviteModal = !this.showSingleInviteModal;
    },
    handleClick({ row }) {
      //   this.$router.push(`/customer/${row.client_id}`);
      this.singleInviteObj = row;
      this.showSingleInviteModal = true;
    },
    refetch(filters) {
      this.handleRefetch(filters, this.fetchCustomers, "invitesRef");
    },
    async fetchCustomers() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch(
          "customer/getInvitedCustomers",
          {
            params,
          }
        );
        const newData = response.data.data;
        const count = response.data.count;
        return { ...response, count, total: count, data: newData };
      };
    },
  },
  computed: {
    getUserRole() {
      return this.$store.state.account.profile.roles[0].role_label
    }
  },
  created() {
    this.fetchCustomers();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>