<template>
	<div class="col-md-12 col-lg-12 col-xl-12">
		<div class="row">
			<div class="col-lg-12" v-if="false">
				<div class="message-info">
					The Sandbox environment is a complete replica of the BluSalt
					production environment, supporting all of the same API
					endpoints. All data and money within the Sandbox is test
					data and completely separate from your production account.
					Happy testing!
				</div>
			</div>
		</div>
		<div class="my-4 flex__between border-bottom pb-4">
			<div>
				<h4>Credit Limit: <b>{{toCurrency(customer.credit_for_apps_and_sec_customers)}}</b></h4>
			</div>
			<div v-if="role === 'Blusalt Admin'">
				<EditAppLimitButton :clientid='clientid' @refetch="fetchCustomer" :customer='customer' />
			</div>

		</div>
		<div class="content d-flex justify-content-end mg-b-15 pt-3">
			<a v-if="role === 'Blusalt Admin'" href="#" class="btn btn-lg btn-primary" @click="computeBundledBilling"
				data-toggle="modal" data-effect="effect-scale" data-backdrop="static" data-target="#apikeyCreateNew">
				<i class="la la-plus"></i> Create Application
			</a>
		</div>
		<div class="row">
			<div class="col-lg-12" v-if="applications.length > 0">
				<div class="app-key-wrap accordion accordion-s2" id="applications" v-for="(app, i) in applications"
					:key="i">
					<div class="app-key-item-wrapper accordion-item">
						<div class="app-key-list-item accordion-heading" data-toggle="collapse"
							:data-target="`#app${i}`">
							<div class="app-key-list-wrap">
								<div class="app-item-icon justify-column">
									<em class="app-icon la la-cube" :class="{
											isBundled: app.billing_type_id == 2,
										}"></em>
								</div>
								<div class="app-name justify-column">
									<div class="app-name-title">
										<div class="ellipsis-text">
											{{ app.app_name }}
											<span v-show="app.is_active == 0" class="badge badge-danger">Inactive</span>
										</div>
									</div>
								</div>
							</div>
							<div class="app-secret-key-wrap">
								<div class="copy-wrap mgb-1-5x mgt-1-5x">
									<span class="copy-feedback" style="display: none;">Copied to Clipboard</span>
									<em class="fas fa-link">
										<span style="font-family: AllianzNeo, sans-serif; marling-left: 10px;">API
											KEY</span>:
									</em>
									<input type="text" class="copy-address" :value="app.api_key" disabled />
								</div>
							</div>
							<div class="app-key-action-wrap ellipsis" data-toggle="tooltip" data-placement="top" title
								data-original-title="more options">
								<span role="button" data-toggle="dropdown" class="dropdown-title"></span>
								<div class="dropdown-menu">
									<!-- <a href class="dropdown-item">
                    <i class="la la-refresh"></i> Regenerate key
                  </a>-->
									<a href="#" @click="populateModal(app)" class="dropdown-item" data-toggle="modal"
										data-effect="effect-scale" data-backdrop="static"
										data-target="#editApplication">
										<i class="la la-edit"></i> Edit
									</a>
									<a v-if="app.is_active == 0" href class="dropdown-item text-sucess"
										@click="populateModal(app, 1)" data-toggle="modal" data-effect="effect-scale"
										data-backdrop="static" data-target="#confirmToggle">
										<i class="la la-check"></i> Activate
									</a>
									<a v-else href class="dropdown-item" @click="populateModal(app, 0)"
										data-toggle="modal" data-effect="effect-scale" data-backdrop="static"
										data-target="#confirmToggle">
										<i class="la la-trash"></i> Deactivate
									</a>
								</div>
							</div>
						</div>
						<div :id="`app${i}`" class="collapse" data-parent="#applications" style>
							<div class="app-content accordion-content">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="false">
										<h5 class="sub-heading">
											Client ID:
											<span>{{ app.client_id }}</span>
										</h5>
									</div>
									<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<h5 class="sub-heading">
											API Credit Limit:
											<span>{{
												app.api_credit_limit == 0
												? "Unlimited"
												: app.api_credit_limit
												}}</span>
										</h5>
									</div>
									<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<h5 class="sub-heading">
											Application Name:
											<span>{{ app.app_name }}</span>
										</h5>
									</div>
									<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<h5 class="sub-heading">
											Total API Calls:
											<span>{{
												app.total_api_calls
												}}</span>
										</h5>
									</div>
									<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<h5 class="sub-heading">
											Active Status:
											<span v-if="app.is_active == 1"
												class="badge badge-success badge-app">Active</span>
											<span v-else class="badge badge-danger badge-app">Inactive</span>
										</h5>
									</div>

									<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<h5 class="sub-heading">
											Used Credit:
											<span>{{
												app.used_credit_on_app
												}}</span>
										</h5>
										<p class="sub-content"></p>
									</div>

									<div class="col-lg-12 col-md-6 col-sm-12" v-if="app.billing_type">
										<h5 class="sub-heading">
											Billing Type:
											<span>{{
												app.billing_type
												.billing_type_label
												}}</span>
										</h5>
									</div>

									<div class="col-lg-12 col-md-6 col-sm-12" v-if="app.app_services">
										<h5 class="sub-heading">
											Services Registered:
											<span v-for="(service,
												i) in app.app_services" :key="i" class="badge badge-service badge-app ml-1 mb-1 mr-1">{{
												service.service_label
												}}</span>
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-12" v-if="applications.length == 0">
				<DefaultContent :image="'empty-resources.png'" :title="'No application created yet'" :subtitle="
						'Start by creation applications and registering services.'
					"></DefaultContent>
			</div>
		</div>
		<ApplicationModal :clientID="clientid" :servicePrices="servicePrices" :bundledPrice="bundlePrice"
			@fetchCustomer="fetchCustomer"></ApplicationModal>
		<ApplicationEditModal v-if="modalClicked" :modalData="modalData" @fetchCustomer="fetchCustomer"
			:clientID="clientid" :servicePrices="servicePrices"></ApplicationEditModal>
		<ConfirmAction ref="confirmActionModal" :modalId="'confirmToggle'" :clientID="clientid"
			:servicePrices="servicePrices" @fetchCustomer="fetchCustomer">
			<p slot="placeholder-content" class="mg-t-15">
				Are you sure you want to
				<span v-text="
						modalData.toggle_status == 0 ? 'deactivate' : 'activate'
					"></span>
				<strong>{{ modalData.app_name }}</strong>
			</p>
			<template slot="placeholder-button">
				<div class="modal-footer">
					<button type="button" @click="toggleApplication" class="btn btn-primary">
						Yes
					</button>
					<button type="button" @click="closeModal" class="btn btn-outline-light">
						No
					</button>
				</div>
			</template>
		</ConfirmAction>
	</div>
</template>

<script>
	import Nprogress from "nprogress";
	import ApplicationModal from "../modals/ApplicationModal";
	import ApplicationEditModal from "../modals/ApplicationEditModal";
	import EditAppLimitButton from '../buttons/EditAppLimitButton/EditAppLimitButton'
	import ConfirmAction from "../modals/confirmAction";
	import {toCurrency} from '../../components/utils/helpers'
	import { notifications } from "../../../public/assets/mixins/notifications";

	export default {
		mixins: [notifications],
		props: {
			applications: {
				type: Array,
			},
			servicePrices: {
				type: Array,
			},
			clientid: {
				type: String,
			},
			role: {
				type: String,
			},
			customer: {
				type: Object
			}
		},
		components: {
			ApplicationEditModal,
			ApplicationModal,
			ConfirmAction,
			EditAppLimitButton
		},
		data() {
			return {
				apps: [],
				isProcessing: true,
				modalData: {},
				modalClicked: false,
				bundlePrice: 0,
			};
		},
		methods: {
			toCurrency,
			fetchCustomer() {
				this.$emit("fetchCustomer");
			},
			computeBundledBilling() {
				console.log("servicees ", this.servicePrices);
				if (this.servicePrices.length == 1) {
					this.bundlePrice = this.servicePrices[0].price_per_call;
				} else {
					const userServices = this.servicePrices;
					let totalBundle = userServices.reduce(
						(sum, objectInArray) => {
							console.log(
								"price per call ",
								objectInArray.price_per_call
							);
							return (
								parseInt(objectInArray.price_per_call) +
								parseInt(sum)
							);
						},
						0
					);
					this.bundlePrice = totalBundle;
				}
			},
			populateModal(data, status = null) {
				console.log("dataa ", data);
				let updatedData = { ...data, toggle_status: status };
				this.modalData = { ...updatedData };
				console.log("modal dataa ", this.modalData);
				this.modalClicked = true;
			},

			formatCreateApplicationData() {
				let requestData = {
					is_active: this.modalData.toggle_status,
				};
				return requestData;
			},
			async toggleApplication(status) {
				console.log("toggle app");
				try {
					let requestPayload = {
						apiKey: this.modalData.api_key,
						appname: this.modalData.app_name,
						payload: this.formatCreateApplicationData(),
					};
					let application = await this.$store.dispatch(
						"application/adminToggleApplication",
						requestPayload
					);
					console.log("[] ->", application);
					this.showSuccessNotification(application.message);
					let self = this;
					setTimeout(() => {
						self.closeModal();
					}, 2500);
					Nprogress.done();
				} catch (error) {
					Nprogress.done();
					console.log("[error] ->", error);
					this.showErrorLoginNotification(error.message);
				}
			},

			closeModal() {
				this.fetchCustomer();
				this.$refs.confirmActionModal.closeModal();
			},
		},
	};
</script>

<style scoped>
	.copy-wrap {
		position: relative;
		width: 60%;
	}

	.isBundled {
		color: #ae13ff !important;
	}

	.copy-feedback {
		display: none;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		line-height: 20px;
		padding: 13px 0;
		font-weight: 500;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		text-align: center;
		background: rgba(255, 255, 255, 0.9);
		border-radius: 4px;
		border: 1px solid rgba(211, 224, 243, 0.5);
		color: #2c80ff;
	}
	.copy-wrap > [class*="fa"] {
		position: absolute;
		top: 0;
		left: 18px;
		line-height: 20px;
		padding: 12px 0;
		font-size: 14px;
		text-align: center;
		color: #495463;
	}

	.copy-address {
		border: none;
		color: #495463;
		line-height: 20px;
		padding: 12px 50px 12px 40px;
		border-radius: 4px;
		margin-left: 60px;
		width: 100%;
		background: #fff;
		cursor: pointer;
	}

	.copy-trigger {
		position: absolute;
		right: 4px;
		top: 4px;
		height: 38px;
		width: 38px;
		line-height: 20px;
		padding: 10px 0;
		text-align: center;
		color: #758698;
		background: #e9eff9;
		border-radius: 4px;
		border: none;
		transition: all 0.4s;
		cursor: pointer;
	}
	.app-key-list-item.accordion-heading {
		cursor: pointer;
	}
	.app-content {
		padding: 20px;
		border-top: 1px solid #e9eff9;
		background: #f9f9f9;
	}

	h5.sub-heading {
		font-size: 18px;
		margin-bottom: 30px;
	}
	.sub-heading span {
		font-weight: 600;
	}

	.badge-app {
		font-weight: 400;
		font-size: 12px;
		padding: 7px;
	}

	.badge-service {
		background-color: #0057f7;
		color: white;
		border-radius: 6px;
	}
</style>
