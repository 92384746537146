<template>
    <div>
        <div v-if="loading">
            <Spinner />
        </div>
        <div class="az-content-body" v-else>
            <div class="row">
                <div class="col-lg-12">
                    <div class="SectionItem no-b-b mb-3">
                        <div class="SectionHeader">
                            <span class="title">
                                <a @click="$router.go(-1)" class="nav-sub-link pointer"><img
                                        src="/assets/img/icons/close.svg" alt="image" height="30" /></a>
                                Subscription Plan Details</span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 card p-4">
                    <div class="flex__between">
                        <h1 class="merchant-title">
                            {{ titleCase(details.plan_name) }}
                        </h1>
                        <div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
                            <EditSubscriptionPlanButton :sub="details" :refetch="fetchSmartId" />
                        </div>
                    </div>
                    <div class="contact-details--card border-0 m-0">
                        <div class="contact-details--card-row">
                            <div class="contact-details--card-field">
                                <span class="field-key">
                                    <div class="contact-details--truncated ">
                                        Plan Cycle
                                    </div>
                                </span>
                                <span class="field-value">
                                    <div class="contact-details--truncated">
                                        {{ titleCase(details.cycle) }}
                                    </div>
                                </span>
                            </div>
                            <div class="contact-details--card-field">
                                <span class="field-key">
                                    <div class="contact-details--truncated">Created Date</div>
                                </span>
                                <span class="field-value">
                                    <div class="contact-details--truncated pointer">
                                        {{ timeDateFormat(details.created_at).date }}
                                        {{ timeDateFormat(details.created_at).time }}
                                    </div>
                                </span>
                            </div>
                            <div class="contact-details--card-field">
                                <span class="field-key">
                                    <div class="contact-details--truncated">Price</div>
                                </span>
                                <span class="field-value">
                                    <div class="contact-details--truncated pointer">
                                        {{ toCurrency(details.price) }}
                                    </div>
                                </span>
                            </div>

                            <div class="contact-details--card-field">
                                <span class="field-key">
                                    <div class="contact-details--truncated">API Call Limit</div>
                                </span>
                                <span class="field-value">
                                    <div class="contact-details--truncated">
                                        {{ details.api_call_limit }}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="contact-details--card border-0 m-0">
                        <div class="contact-details--card-row m-0">
                            <div class="">
                                <span class="field-key">
                                    <div class="contact-details--truncated text-uppercase mb-3">
                                        Services
                                    </div>
                                </span>
                                <span class="field-value">
                                    <div class="">
                                        <span class="field-value">
                                            <span v-for="(service, i) in details.services" :key="i"
                                                class="badge badge-service badge-app ml-1 mb-1 mr-1">{{ service
                                                }}</span>
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import EditSubscriptionPlanButton from './buttons/EditSubscriptionPlanButton'

export default {
    name: "Plan Details",
    mixins: [componentData],
    data() {
        return {
            loading: true,
            details: null,
        };
    },
    components: {
        EditSubscriptionPlanButton
    },
    computed: {
        getUserRole() {
            return this.$store.state.account.profile.roles[0].role_label
        }
    },
    methods: {
        async fetchSmartId() {
            const response = await this.$store.dispatch(
               "services/getSubscriptionPlanDetails",
                {
                    id: this.$route.params.planId,
                }
            );
            this.details = response.data;
            this.loading = false;

        },
    },
    created() {
        this.fetchSmartId();
    },
};
</script>