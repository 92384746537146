<template>
  <div>
    <TableWrapper :loading="loading" errorMsg="">
      <template slot="content">
        <div class="p-4">
          <div class="border-bottom pb-3 mb-4 flex__between flex-wrap">
            <div class="">
              <h4 class="head4">
                {{ wallet.currency || "NGN" }}
                {{
                  wallet.balance ? Number(wallet.balance).toLocaleString() : 0
                }}
              </h4>
              <p class="call-name pt-2">Current NGN Wallet Banlace</p>
            </div>

            <div class="">
              <h4 class="head4">
                {{
                  customer.total_credit_loaded
                    ? toCurrency(customer.total_credit_loaded)
                    : 0
                }}
              </h4>
              <p class="call-name pt-2">Total Credit Loaded</p>
            </div>

            <div v-if="role === 'Blusalt Admin'">
              <AddCreditButton
                :clientid="customer.client_id"
                :refetch="refetch"
              />
            </div>
          </div>

          <h5 class="font-weight-bold">Wallet Info</h5>

          <div class="row profile pt-3">
            <div class="col-lg-4 col-12">
              <p>Account Name</p>
              <h5>{{ wallet.account_name || "None" }}</h5>
            </div>

            <div class="col-lg-4 col-12">
              <p>Account Number</p>
              <div class="d-flex">
                <h5 class="m-0">
                  {{ wallet.account_number || "None" }}
                </h5>
                <img
                  v-if="wallet.account_number"
                  src="/assets/img/copy.svg"
                  class="pointer ml-3"
                  alt=""
                  @click="copy(wallet.account_number || '')"
                />
              </div>
            </div>

            <div class="col-lg-4 col-12">
              <p>Bank Name</p>
              <h5>{{ wallet.bank_name || "None" }}</h5>
            </div>
          </div>
        </div>
      </template>
    </TableWrapper>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMessage"
      :refetch="refetch"
    >
      <template slot="content">
        <div v-if="logs.data" class="px-3">
          <h5 class="font-weight-bold mt-3">Account Credit Log</h5>
          <AccountCreditLog :logs="logs" />
        </div>
      </template>
    </TableWrapper>
  </div>
</template>

<script>
import {
  notifications,
  componentData,
} from "../../../../../public/assets/mixins";
import AddCreditButton from "../../buttons/AddCreditButton/AddCreditButton";
import AccountCreditLog from "../AccountCreditLog/AccountCreditLog";

export default {
  mixins: [notifications, componentData],
  name: "CustomerWallet",
  props: ["loading", "customer", "refetch", "role"],
  components: {
    AddCreditButton,
    AccountCreditLog,
  },
  methods: {
    async copy(value) {
      const copied = this.copyToClipboard(value);
      copied && this.showSuccessNotification("Account Number Copied!");
    },
  },
  computed: {
    wallet() {
      const wallets = this.customer.customer_wallet || [];
      return wallets && wallets.length ? wallets[0] : {};
    },
    logs() {
      return this.customer.credit_history ? this.customer.credit_history : {};
    },
    errorMessage() {
      const error = this.customer.customer_wallet
        ? undefined
        : "Error Fetching Customer Wallet";
      return error;
    },
  },
};
</script>
