<template>
  <SideModal :show="show" :toggle="toggle" :title="`Invite Information`" :subTitle="'See your invite status'">
    <div class="d-flex">
      <span class="badge badge-app" :class="
          singleInviteObj.is_active == 1 ? 'badge-success' : 'badge-danger'
        ">
        {{ singleInviteObj.is_active === 1 ? "Active" : "Inactive" }}</span>
    </div>
    <div class="row mt-4 pt-4">
      <div class="col-6">
        <p>
          EMAIL <br />
          <span>{{ singleInviteObj.email }}</span>
        </p>
      </div>
      <div class="col-6">
        <p>
          CUSTOMER'S NAME <br />
          <span>{{ singleInviteObj.first_name }} </span>
          <span>{{ singleInviteObj.last_name }} </span>
        </p>
      </div>
      <br />
      <div class="col-6 mt-4">
        <p>
          INVITATION DATE <br /><span>{{ singleInviteObj.created_at }}</span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <div v-if="singleInviteObj.is_active == 1">
      <router-link to="/customers" class="btn btn-lg btn-primary">
        View Customer
      </router-link>
    </div>
    <div v-else>
      <button type="submit" class="btn btn-lg " @click="handleSendInvite" v-if="role === 'Blusalt Admin'">
        <Spinner v-if="loading" /> <span v-else>Resend Invite </span>
      </button>
    </div>
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import Spinner from "../../../components/utils/Spinner/Spinner.vue";
import customer from "../../../services/customer";

export default {
  mixins: [notifications],
  name: "InviteDetailModal",
  data() {
    return {loading: false};
  },
  props: ["toggle", "show", "singleInviteObj", "role"],
  components: {
    SideModal,
    Spinner,
  },
  methods: {
    handleCloseModal() {
      this.toggle();
    },
    async handleSendInvite() {
      this.loading = true;
      try {
        let { data } = await customer.ReSendInvite({customerEmail: this.singleInviteObj.email});
        this.loading = false;
        this.showSuccessNotification(data.message);
        this.toggle();
      } catch (error) {
        this.showErrorNotification(error.response.data.message);
        this.loading = false;
        throw Error(error.response.data.message);
      }
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
