<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-primary mr-3"
      @click="openAddProviderModal"
    >
      Make {{ action }}
    </button>
    <Modal
      id="makeInactive"
      :title="`Make ${action}`"
      :toggle="closeModal"
      :loading="loading"
    >
      <h3>Are you sure you want to make {{ action.toLowerCase() }}?</h3>
      <p>
        This would make this user {{ action.toLowerCase() }}. Are you sure you
        want to continue?
      </p>
      <form @submit.prevent="makeInactive">
        <FormButtons
          :loading="loading"
          :handleCloseModal="closeModal"
          :closeBtnName="`No, Don't Make ${action}`"
          :btnName="`Yes, Make ${action}`"
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "@/components/utils/Modal/Modal";
import FormButtons from "@/components/utils/FormButtons/FormButtons";
import CustomInput from "@/components/utils/CustomInput/CustomInput";
import CustomSelect from "@/components/utils/CustomSelect/CustomSelect";

export default {
  mixins: [notifications],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    action() {
      return this.teamInformation?.status === "active" ? "Inactive" : "Active";
    },
  },
  props: ["teamInformation", "refetch"],
  methods: {
    openAddProviderModal() {
      $("#makeInactive").modal("show");
    },
    closeModal() {
      $("#makeInactive").modal("hide");
    },
    async makeInactive() {
      try {
        this.loading = true;
        let response = await this.$store.dispatch(
          "account/updateTeamMemberStatus",
          {
            userId: this.teamInformation.id,
            status:
              this.teamInformation.status === "active" ? "inactive" : "active",
          }
        );

        this.loading = false;
        this.closeModal();
        this.refetch();
        this.showSuccessNotification(response.message);
      } catch (error) {
        this.loading = false;
        this.showErrorNotification(error.message);
      }
    },
  },
  components: {
    Modal,
    FormButtons,
    CustomInput,
    CustomSelect,
  },
};
</script>

<!-- please rebuild -->
