<template>
  <div v-if="isLoading == true" class="flex__center w-100">
    <Loader />
  </div>
  <div class="az-content-body" v-else>
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title"> {{ name }} Details</span>
            <div class="description">
              View all the details of a single customer
            </div>
          </div>
          <div class="content">
            <router-link to="/customers" class="btn btn-lg btn-outline-danger">
              <i class="la la-arrow-left"></i> Back
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account">
          <a href="#profile" data-toggle="tab" class="nav-link active show">Profile</a>
          <a href="#applications" data-toggle="tab" class="nav-link">Applications</a>
          <a href="#wallet" data-toggle="tab" class="nav-link">Wallet</a>
          <a href="#services" data-toggle="tab" class="nav-link">Services</a>
          <a href="#activity" data-toggle="tab" class="nav-link">Activity Log</a>
          <a href="#secondaryCustomers" v-if="apiUser.user.roles[0].role_label == 'Whitelabelled Customer'"
            data-toggle="tab" class="nav-link">Secondary Customers</a>
          <a href="#team" data-toggle="tab" class="nav-link">Team</a>
          <a href="#compliance" data-toggle="tab" class="nav-link">Compliance</a>
          <a href="#audit" data-toggle="tab" class="nav-link">Audit Trail</a>
        </nav>
      </div>
    </div>

    <div class="tab-content">
      <div id="profile" class="row tab-pane active show ">
        <div class="col-lg-12 col-md-12">
          <div class="user-details--wrapper billing">
            <!-- Secondary customer -->
            <div class="user-details-left-header">
              <div class="user-details-info">
                <div class="user-details-avatar user-avatar online" v-if="false">
                  <img :src="
                      `/assets/img/avatars/${Math.floor(Math.random() * 18) +
                        1}.png`
                    " alt />
                </div>
                <div class="user-details--top-info">
                  <h2 class="user-profile-name">
                    {{ customer.user.firstname }}
                    {{ customer.user.lastname }}
                  </h2>
                  <h5 class="user-profile-email">
                    {{ customer.user.email }}
                  </h5>
                  <p class="mt-2">Client ID: {{ customer.client_id }}</p>
                </div>
              </div>
              <div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
                <a href="#" class="mr-1 btn btn-lg btn-dark" @click="populateModal(customer)" data-toggle="modal"
                  v-if="userRole !== 'Secondary Customer'" data-effect="effect-scale" data-backdrop="static"
                  data-target="#editAccountService">Edit Services</a>
                <button href class="btn btn-lg btn-dark mr-1" :disabled="currentUserRole === 'Whitelabelled Customer'"
                  v-if="
                    currentUserRole === 'Primary Customer' &&
                      customer.is_verified
                  " @click.prevent="activateWhiteLabelling">
                  <span>Activate Whitelabelling</span>
                </button>
                <button href class="btn btn-lg btn-dark mr-1" :disabled="currentUserRole === 'Whitelabelled Customer'"
                  v-if="
                    currentUserRole == 'Whitelabelled Customer' &&
                      customer.is_verified
                  " @click.prevent="activateWhiteLabelling">
                  <span>Whitelabel activated</span>
                </button>
                <a href class="btn btn-lg btn-az-secondary mr-1" v-if="!customer.is_verified && toShow == false"
                  @click.prevent="verifyCustomer">
                  <span>Activate</span>
                </a>
                <a href class="btn btn-lg btn-danger mr-1" v-if="customer.is_verified && toShow == false"
                  @click.prevent="verifyCustomer">
                  <span>Deactivate</span>
                </a>

                <a href="#" @click="toShow = true" class="btn btn-lg btn-light mr-2" v-if="toShow == false">Edit
                  Profile</a>
                <a href="#" v-if="toShow == true" @click="toShow = false" class="btn btn-lg btn-danger">Cancel</a>
              </div>
            </div>
            <transition mode="out-in" name="fade">
              <AccountProfileEdit @closeEdit="closeEdit" v-if="toShow == true" :apiUser="apiUser"
                @getProfile="fetchCustomer"></AccountProfileEdit>
              <CustomerProfileInfo :customer="customer" :activeServices="activeServices" v-else></CustomerProfileInfo>
            </transition>
          </div>

          <!-- CHange Password  -->
          <div class="user-settings-section" v-if="customer.primary_customer_info">
            <div class="user-settings-section-header">
              <div class="user-settings-section-header-text">
                <h1 style="font-size: 24px">
                  Primary Customer Details
                </h1>
                <div class="text-span mt-2" style="font-size: 16px">
                  The customer is registered under
                  <strong>{{ customer.primary_customer_info.user.firstname }}
                    {{ customer.primary_customer_info.user.lastname }}</strong>
                </div>
                <div class="text-span mt-2" style="font-size: 16px">
                  Organization :
                  <strong>{{
                    customer.primary_customer_info.organization_name
                    }}</strong>
                </div>
              </div>
              <div class>
                <a @click.prevent="
                    navigateToCustomer(customer.primary_customer_info.client_id)
                  " class="btn btn-lg btn-light">View Customer</a>
              </div>
            </div>

            <transition mode="out-in" name="fade">
              <div class="user-setting-password-edit" :class="{ closed: !showPasswordEdit }">
                <form @submit.prevent="changePassword">
                  <div class="row mg-b-10">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Current password</label>
                        <input type="text" class="form-control input-bordered" v-model="PM.currentPassword" />
                      </div>
                    </div>
                  </div>
                  <div class="row mg-b-10">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">New password</label>
                        <input type="password" class="form-control input-bordered" v-model="PM.password" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Confirm new password</label>
                        <input type="password" class="form-control input-bordered" v-model="PM.confirmPassword" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button type="submit" class="btn btn-primary">
                        Change password
                      </button>
                      <button @click="clearPassword" type="button" class="btn btn-outline-danger ml-2">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </transition>
          </div>

          <!-- Deactivate Account -->
          <div class="gaps-4x"></div>
        </div>
      </div>

      <div id="applications" class="row tab-pane">
        <CustomerApplication :role="getUserRole" :clientid="clientid" :servicePrices="customer.customerServices"
          :applications="customer.applications" :customer="customer" @fetchCustomer="fetchCustomer">
        </CustomerApplication>
      </div>

      <div id="wallet" class="row tab-pane">
        <CustomerWallet :role="getUserRole" :loading="isOthersLoading" :customer="customer"
          :refetch="fetchCustomerAdditionalDetails" />
      </div>

      <div id="services" class="row tab-pane">
        <CustomerBilling :role="getUserRole" :customer="customer" @fetchCustomer="fetchCustomer"></CustomerBilling>
      </div>

      <div id="activity" class="row tab-pane">
        <AccountActivity :clientid="clientid" :applications="customer.applications"
          :customerServices="customer.customerServices"></AccountActivity>
      </div>
      <div id="secondaryCustomers" class="row tab-pane"
        v-if="apiUser.user.roles[0].role_label == 'Whitelabelled Customer'">
        <AccountSecondaryCustomers :customer="customer"></AccountSecondaryCustomers>
      </div>
      <div id="team" class="row tab-pane">
        <Teams :role="getUserRole" :customer="customer" :loading="isOthersLoading"
          :refetch="fetchCustomerAdditionalDetails" />
      </div>
      <div id="audit" class="row tab-pane">
        <AccountAuditTrail :clientid="clientid" :applications="customer.applications"
          :customerServices="customer.customerServices"></AccountAuditTrail>
      </div>

      <div id="compliance" class="row tab-pane">
        <Compliance :teams="customer.teamMembers" :roles="customer.user.roles" />
      </div>
    </div>
    <AccountServiceEditModal @refetchApplication="fetchServiceCategories" :modalData="modalData"
      :serviceCategories="serviceCategories" :activeService="activeServices"></AccountServiceEditModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import CustomerBilling from "@/components/account/CustomerBilling";
import AccountSecondaryCustomers from "@/components/account/AccountSecondaryCustomers";
import CustomerProfileInfo from "@/components/account/CustomerProfileInfo";
import AccountProfileEdit from "@/components/account/AdminEditProfile";
import AccountActivity from "@/components/account/AccountActivity";
import AccountAuditTrail from "@/components/account/AccountAuditTrail";
import CustomerApplication from "@/components/account/CustomerApplication";
import AccountServiceEditModal from "../modals/AccountServiceEditModal/AccountServiceUpdateModal";
import CustomerWallet from "./CustomerWallet/CustomerWallet";
import Compliance from "./Compliance/Compliance";
import Teams from "./Teams/Teams";

import { notifications } from "../../../../public/assets/mixins/notifications";
export default {
  mixins: [notifications],
  props: {
    clientid: {
      type: String,
    },
  },
  name: "Customer-Detail",
  components: {
    CustomerBilling,
    AccountActivity,
    CustomerProfileInfo,
    AccountProfileEdit,
    CustomerApplication,
    AccountSecondaryCustomers,
    AccountServiceEditModal,
    CustomerWallet,
    Teams,
    AccountAuditTrail,
    Compliance,
  },

  data() {
    return {
      isLoading: true,
      isOthersLoading: true,
      apiUser: {},
      customer: {},
      toShow: false,
      modalClicked: false,
      modalData: {},
      serviceCategories: [],
      activeServices: [],
      showPasswordEdit: false,
      PM: {
        password: "",
        currentPassword: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
    currentUserRole() {
      return this.apiUser.user.roles[0].role_label;
    },
    getUserRole() {
      return this.$store.state.account.profile.roles[0].role_label
    },
    name() {
      return this.customer
        ? this.customer.organization_name ||
            `${this.customer.user.firstname} ${this.customer.user.lastname}`
        : "";
    },
  },
  methods: {
    navigateToCustomer(clientid) {
      location.replace(`/customer/${clientid}`);
    },
    populateModal(data) {
      this.modalData = { ...data };
      this.modalClicked = true;
    },
    async activateWhiteLabelling() {
      try {
        let response = await this.$store.dispatch(
          "account/grantWhitelabelAccess",
          this.clientid
        );
        this.showSuccessNotification(response.message);
        console.log("[grant whitelabel access by admin] ->", response);
        this.fetchCustomer();
        Nprogress.done();
      } catch (error) {
        console.log("[grant whitelabel access by admin] ->", error);
        this.showErrorRegistrationNotification(error.message);
        Nprogress.done();
      }
    },
    closeEdit() {
      this.toShow = !this.toShow;
    },
    clearPassword() {
      this.PM.currentPassword = "";
      this.PM.password = "";
      this.PM.confirmPassword = "";

      this.showPasswordEdit = !this.showPasswordEdit;
    },
    async fetchServiceCategories() {
      try {
        let response = await this.$store.dispatch(
          "services/getServiceCategoriesWithServices",
          {
            clientid: this.clientid,
          }
        );

        let bigArr = [];

        for (let index = 0; index < response.data.length; index++) {
          let arr = response.data[index].apiServices.filter(
            (dt) => dt.is_activated
          );
          bigArr.push(...arr);
        }
        this.activeServices = bigArr;

        this.serviceCategories = response.data;
      } catch (error) {
        console.log("[error] ->", error);
      }
    },
    async changePassword() {
      let credentials = {
        old_password: this.PM.currentPassword,
        new_password: this.PM.password,
        confirm_new_password: this.PM.confirmPassword,
      };

      try {
        let response = await this.$store.dispatch(
          "account/changePassword",
          credentials
        );
        this.showSuccessNotification(response.message);
        console.log("[response from password change] ->", response);
        this.clearPassword();
        Nprogress.done();
      } catch (error) {
        console.log("[errors from password change] ->", error);
        this.showErrorRegistrationNotification(error.message);
        Nprogress.done();
      }
    },
    async verifyCustomer() {
      let verificationData = {
        is_verified: !this.customer.is_verified,
        is_active: !this.customer.is_active,
        clientID: this.customer.client_id,
      };
      try {
        let response = await this.$store.dispatch(
          "account/verifyCustomer",
          verificationData
        );
        this.showSuccessNotification(response.message);
        this.fetchCustomer();
        console.log("[verification response] ->", response);
        Nprogress.done();
      } catch (error) {
        console.log("[verification error] ->", error);
        this.showErrorRegistrationNotification(error.message);
        Nprogress.done();
      }
    },
    async fetchCustomerDetails() {
      try {
        this.isLoading = true;
        let apiResponseDetails = await this.$store.dispatch(
          "customer/getSingleCustomerDetails",
          this.clientid
        );

        const customer = {
          ...this.customer,
          ...apiResponseDetails,
        };

        this.customer = customer;
        this.apiUser = customer;

        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCustomerAdditionalDetails() {
      try {
        this.isOthersLoading = true;
        let apiResponseAdditionalDetails = await this.$store.dispatch(
          "customer/getSingleCustomerAdditionalDetails",
          this.clientid
        );
        const customer = {
          ...this.customer,
          ...apiResponseAdditionalDetails,
        };
        this.customer = customer;
        this.apiUser = customer;
        this.isOthersLoading = false;
      } catch (error) {
        this.isOthersLoading = false;
        this.showErrorLoginNotification(error.message);
      }
    },
    fetchCustomer() {
      this.fetchCustomerDetails();
      this.fetchCustomerAdditionalDetails();
    },
  },
  async mounted() {
    this.fetchCustomer();
    this.fetchServiceCategories();
  },
};
</script>
