<template>
  <div class="az-content-body" v-if="teamInformation">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">
              <a @click="$router.go(-1)" class="nav-sub-link"><img src="/assets/img/icons/go-back.svg" alt="image"
                  height="30" /></a>
              Customer Information</span>
          </div>
          <div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
            <UpdateRoleModal :teamInformation="teamInformation" :refetch="fetchTeamDetails" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 card p-4">
        <h1 class="merchant-title">
          <span class="mr-4"><img height="35" src="/assets/img/avatar1.svg"
              alt="" /></span>{{teamInformation.user.firstname}} {{teamInformation.user.lastname}}
        </h1>
        <div class="contact-details--card">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Email Address
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{teamInformation.user.email}}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Phone Number</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{teamInformation.user.phone_number}}
                </div>
              </span>
            </div>

            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Address</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{teamInformation.user.address_one || 'N/A'}}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">City</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{teamInformation.user.city || 'N/A'}}
                </div>
              </span>
            </div>
          </div>
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Role
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  <span v-if="!teamInformation.role"> N/A</span>
                  <span v-else>{{ teamInformation.role.name }}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="my-4 d-flex flex__between">
      <div>
        <h4>Trigger Password Reset</h4>
        <p>Team member recieve email to change the password</p>
      </div>
      <div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
        <PasswordResetModal :teamInformation="teamInformation" />
      </div>
    </div>
    <hr />
    <div class="my-4 d-flex flex__between">
      <div>
        <h4>Two-Factor Authentication</h4>
        <p>Disable 2FA for team members</p>
      </div>
      <div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
        <DisableMFAModal :teamInformation="teamInformation" :refetch="fetchTeamDetails" />
      </div>
    </div>
    <hr />
    <div class="my-4 d-flex flex__between">
      <div>
        <h4>Cutomer De-activation</h4>
        <p>Make customer inactive</p>
      </div>
      <div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
        <MakeInactiveModal :teamInformation="teamInformation" :refetch="fetchTeamDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import UpdateRoleModal from "./modals/UpdateRoleModal.vue";
import PasswordResetModal from "./modals/PasswordResetModal.vue";
import DisableMFAModal from "./modals/DisableMFAModal.vue";
import MakeInactiveModal from "./modals/MakeInactiveModal.vue";

export default {
  name: "TeamDetail",
  props: {},
  data() {
    return {
      teamInformation: null,
    };
  },
  methods: {
    async fetchTeamDetails() {
      let response = await this.$store.dispatch(
        "customer/getCustomerTeamDetails",
        { id: this.$route.params.id, clientid: this.$route.params.clientid }
      );
      this.teamInformation = response.result.teamMember;
    },
  },
  computed: {
    getUserRole() {
      return this.$store.state.account.profile.roles[0].role_label
    }
  },
  components: {
    UpdateRoleModal,
    PasswordResetModal,
    DisableMFAModal,
    MakeInactiveModal,
  },
  async mounted() {
    await this.fetchTeamDetails();
  },
};
</script>
<style scoped></style>
