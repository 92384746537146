import account from "@/services/account";
import { apiClient } from "@/services/api";

export default {
	namespaced: true,
	state: {
		token: "",
		profile: {},
		userRole: "",
		clientID: "",
	},
	mutations: {
		SET_CLIENT_ID(state, clientID) {
			localStorage.setItem("local-ID", clientID);
			state.clientID = clientID;
		},
		SET_TOKEN(state, token) {
			state.token = token;
		},
		ClEAR_TOKEN(state) {
			state.token = "";
		},
		SET_USER_PROFILE(state, profile) {
			state.profile = profile;
		},
		SET_USER_ROLE(state, userRole) {
			state.userRole = userRole;
			localStorage.setItem("identifiedAs", userRole);
		},
	},
	actions: {
		async getDashboardAnalytics(context) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				const { data } = await account.getDashboardAnalytics();
				return data;
			} catch (error) {
				console.log(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async getProfile({ commit, dispatch }) {
			let userData;
			try {
				let { data } = await account.getProfile();
        let { data: currentUser } = await account.getCurrentUser();
				userData = data.results[0];
        userData.role = currentUser?.data?.role;
				commit("SET_USER_PROFILE", userData);
				if (userData.roles[0].role_label == "Api Customer")
					commit("SET_CLIENT_ID", userData.api_customer.client_id);
				commit("SET_USER_ROLE", userData.roles[0].role_label);
				return data.results[0];
			} catch (error) {
				if (error.response.data.status_code == 401) {
					dispatch("account/logout", null, { root: true });
				}
				throw Error(error.response.data.message);
			}
		},
		async editProfile({ commit, dispatch }, profileDetails) {
			try {
				let { data } = await account.editProfile(profileDetails);
				dispatch("getProfile");
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async AdminEditSecondaryCustomerProfile(
			{ commit, dispatch },
			profileDetails
		) {
			try {
				console.log(profileDetails);
				let { data } = await account.AdminEditSecondaryCustomerProfile(
					profileDetails
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async grantWhitelabelAccess({ commit, dispatch }, credientials) {
			try {
				let { data } = await account.adminWLRegistration(credientials);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async verifyCustomer({ commit, dispatch }, verificationData) {
			try {
				let { data } = await account.verifyCustomer(verificationData);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
    async getTeam({}, { params }) {
			try {
				let { data } = await account.getTeam(params);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
    async getTeamMember({}, id) {
			try {
				let { data } = await account.getTeamMember(id);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
    async addTeamMember({}, payload) {
			try {
				let { data } = await account.addTeamMember(payload);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
    async updateTeamMember({}, payload) {
			try {
				let { data } = await account.updateTeamMember(payload);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
    async updateTeamMemberStatus({}, payload) {
			try {
				let { data } = await account.updateTeamMemberStatus(payload);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
    async changePassword(context, crednetials) {
			try {
				const apiResponse = await account.passwordChange(crednetials);
				console.log("[] ->", apiResponse.data);
				return apiResponse.data;
			} catch (error) {
				console.log(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		logout({ commit }) {
			commit("ClEAR_TOKEN");
			localStorage.clear();
			window.location.href = `${window.location.origin}/cdn-cgi/access/logout`;
			// location.reload();
		},
	},
	getters: {
		getClientID(state) {
			return state.clientID;
		},
	},
};
