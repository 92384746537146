import Vue from "vue";
import Router from "vue-router";

// layout
import TheLayout from "./components/modules/TheLayout.vue";

// UnAuthenticated Routes
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import PasswordReset from "./views/PasswordReset.vue";
import ActivateAccount from "./views/ActivateAccount.vue";
import FourohFour from "./views/404.vue";

// Authenticated Routes
import Dashboard from "./views/Dashboard.vue";
import Account from "./views/Account.vue";
import Transactions from "./views/Transactions.vue";
import Services from "./views/Settings/Services/Services";
import ServiceProviders from "./views/ServiceProviders.vue";
import Providers from "./views/Settings/ProvidersSettings/ProvidersSettings";
import CreditLogs from "./views/CreditLogs/CreditLogs.vue";
import CreditLogsOverview from "./views/CreditLogs/Overview.vue";
import AuditTrails from "./views/AuditTrails.vue";
import CreateCustomer from "./views/CreateCustomer.vue";
import Customers from "./views/Customers/Customers";
import PaymentMerchants from "./views/Customers/PaymentMerchants";
import PaymentDisputes from "./views/payments/PaymentDisputes";
import AllPaymentDisputes from "./views/Payment/AllPaymentDisputes";
import PaymentChargebacks from "./views/Payment/PaymentChargebacks";
import PaymentRefund from "./views/Payment/PaymentRefund";
import AllPaymentPayouts from "./views/Payment/AllPaymentPayouts";
import PayoutDetailPage from "./views/Payment/PayoutDetailPage";
import AllPaymentProviders from "./views/Payment/PaymentProviders";
import PaymentProviderDetail from "./views/Payment/PaymentProviderDetail/PaymentProviderDetail";
import Invites from "./views/Customers/Invites";
import CustomerDetail from "./views/Customers/CustomerDetails/CustomerDetail";
import TeamDetail from "./views/Customers/CustomerDetails/Teams/TeamDetail/TeamDetail";
import MerchantDetail from "./views/Customers/MerchantDetail/MerchantDetail";
import VerificationsDetail from "./views/Customers/MerchantDetail/VerificationsDetail";
import MerchantCustomerDetail from "./views/Customers/MerchantDetail/Tabs/MerchantCustomerDetails/Index";
import SingleWallet from "./views/Customers/MerchantDetail/SingleWallet";
import SingleSubAccount from "./views/Customers/MerchantDetail/SingleSubAccount";
import SingleReservedAccount from "./views/Customers/MerchantDetail/SingleReservedAccount";
import InvoiceDetail from "./views/Customers/MerchantDetail/Tabs/InvoiceDetail/InvoiceDetail";
import SinglePaymentLink from "./views/Customers/MerchantDetail/SinglePaymentLink";
import Settings from "./views/Settings.vue";
import WhiteLabelRequests from "./views/WhiteLabel.vue";
import FTtransactions from "./views/FundTransfer/FTtransactions.vue";
import FTOverview from "./views/FundTransfer/FTOverview.vue";
import PaymentOverview from "./views/Payment/PaymentOverview.vue";
import PaymentTransactions from "./views/Payment/PaymentTransactions.vue";
import FTViewBusinessInfo from "./views/FTViewBizInfo.vue";
import FTEditBusinessInfo from "./views/FTEditBizInfo.vue";
import FTCustomerBizInfo from "./views/FTCustomerBizInfo.vue";
import SingleFTTransaction from "./components/fundsTransfer/SingleTransaction.vue";
import CustomerTransactions from "./components/fundsTransfer/CustomerTransactions.vue";

// Logs
import SMSLog from "./views/Logs/SMSLogs";
import ServiceProviderLogs from "./views/Logs/ServiceProviderLogs.vue";
import ProviderListing from "./views/ServiceProvidersLog/Providers/Providers.vue";
import ProviderOverview from "./views/ServiceProvidersLog/Overview/Overview";
import ProviderDetails from "./views/ServiceProvidersLog/Providers/ProviderDetail/ProviderDetail"
import BillPaymentLogs from "./views/Logs/BillPaymentLogs.vue";
import BusinessRegistrationLogs from "./views/Logs/BusinessRegistrationLogs/BusinessRegistrationLogs.vue";
import BusinessRegistrationLogsDetails from "./views/Logs/BusinessRegistrationLogs/BusinessRegistrationLogsDetails.vue";
import ServiceReports from "./views/Logs/ServiceReports/ServiceReports.vue";
import AddressVerificationReport from "./views/Logs/AddressVerificationReport";
import ServiceReportDetails from "./views/Logs/ServiceReports/ServiceReportDetails/ServiceReportDetails.vue";
import MainInformation from "./views/Logs/ServiceReports/ServiceReportDetails/MainInformation/MainInformation.vue";
import OtherInformation from "./views/Logs/ServiceReports/ServiceReportDetails/OtherInformation/OtherInformation.vue";
import Ownership from "./views/Logs/ServiceReports/ServiceReportDetails/Ownership/Ownership.vue";
import Personnel from "./views/Logs/ServiceReports/ServiceReportDetails/Personnel/Personnel";
import ContactInformation from "./views/Logs/ServiceReports/ServiceReportDetails/ContactInformation/ContactInformation";
import BulkVerificationDetails from "./views/Logs/ServiceReports/BulkVerificationDetails/BulkVerificationDetails.vue";

// SmartId
import PersonalSmartID from "./views/SmartId/PersonalSmartID";
import BusinessSmartId from "./views/SmartId/BusinessSmartId";
import VerificationSubscriptions from "./views/SmartId/VerificationSubscriptions";
import VerificationSubscriptionDetails from "./views/SmartId/VerificationSubscriptionDetails";
import PersonalSmartIdDetails from "./views/SmartId/PersonalSmartIdDetails";
import TeamMember from "./views/TeamMember.vue";


Vue.use(Router);

export const routes = [
  // Login Route
  {
    path: "/404",
    name: "404",
    component: FourohFour,
    meta: {
      title: "Blusalt Admin - Something went wrong ",
    },
  },
  {
    path: "/",
    redirect: "/dashboard",
    name: "Login",
    component: Login,
    meta: {
      title: "Blusalt Admin - Login to blusalt ",
    },
  },
  {
    path: "/login",
    redirect: "/",
    component: Login,
    meta: {
      title: "Blusalt Admin - Login to blusalt ",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Blusalt Admin - Lets help you recover your password",
    },
  },
  {
    path: "/activate-account/:token",
    props: true,
    name: "ActivateAccount",
    component: ActivateAccount,
    meta: {
      title: "Blusalt Admin - Activate your account to continue using blusalt",
    },
  },
  {
    path: "/password-reset/:token",
    props: true,
    name: "PasswordReset",
    component: PasswordReset,
    meta: {
      title: "Blusalt Admin - Lets help you recover your password",
    },
  },
  // Dashboard & Children Components Routes
  {
    path: "/dashboard",
    component: TheLayout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
          title: "Blusalt Admin - Welcome to your dashboard",
        },
      },
      {
        path: "/account",
        name: "Account",
        component: Account,
        meta: {
          title: "Blusalt Admin - view your account",
        },
      },
      {
        path: "/account/team/:id",
        name: "Account Team Member",
        component: TeamMember,
        meta: {
          title: "Blusalt Admin - view your team member account",
        },
      },
      {
        path: "/transactions",
        name: "Transactions",
        component: Transactions,
        meta: {
          title: "Blusalt Admin - View all the transactions logs",
        },
      },
      {
        path: "/view-business-information/:id",
        name: "FTViewBusinessInfo",
        component: FTViewBusinessInfo,
        meta: {
          title: "Blusalt Admin - FT Business Information",
        },
      },
      {
        path: "/edit-business-information/:id",
        name: "FTEditBusinessInfo",
        component: FTEditBusinessInfo,
        meta: {
          title: "Blusalt Admin - FT Business Information",
        },
      },
      {
        path: "/customer-business-information",
        name: "FTCustomerBizInfo",
        component: FTCustomerBizInfo,
        meta: {
          title: "Blusalt Admin - FT Customer Business Information",
        },
      },
      {
        path: "/funds-transfer-transactions",
        name: "FTtransactions",
        component: FTtransactions,
        meta: {
          title: "Blusalt Admin - View FT Transactions in the system",
        },
      },
      {
        path: "/single-transaction/:transaction_reference",
        name: "SingleFTTransaction",
        component: SingleFTTransaction,
        meta: {
          title: "Blusalt Admin - View Single FT Transaction in the system",
        },
      },
      {
        path: "/funds-transfer-overview",
        name: "FTOverview",
        component: FTOverview,
        meta: {
          title: "Blusalt Admin - FT Overview",
        },
      },
      {
        path: "/payment-overview",
        name: "PaymentOverview",
        component: PaymentOverview,
        meta: {
          title: "Blusalt Admin - Payment Overview",
        },
      },
      {
        path: "/payment-transactions",
        name: "PaymentTransactions",
        component: PaymentTransactions,
        meta: {
          title: "Blusalt Admin - View Payment Transactions in the system",
        },
      },
      {
        path: "/payment-disputes",
        name: "AllPaymentDisputes",
        component: AllPaymentDisputes,
        meta: {
          title: "Blusalt Admin - View Payment Transactions in the system",
        },
      },
      {
        path: "/payment-chargebacks",
        name: "PaymentChargebacks",
        component: PaymentChargebacks,
        meta: {
          title: "Blusalt Admin - View Payment Transactions in the system",
        },
      },
      {
        path: "/payment-refunds",
        name: "PaymentRefund",
        component: PaymentRefund,
        meta: {
          title: "Blusalt Admin - View Payment Transactions in the system",
        },
      },
      {
        path: "/payment-payouts",
        name: "AllPaymentPayouts",
        component: AllPaymentPayouts,
        meta: {
          title: "Blusalt Admin - View Payment Transactions in the system",
        },
      },
      {
        path: "/payment-payouts/:id",
        name: "Payout Detail Page",
        component: PayoutDetailPage,
        meta: {
          title: "Blusalt Admin - View Payment Transactions in the system",
        },
      },
      {
        path: "/payment-providers",
        name: "AllPaymentProviders",
        component: AllPaymentProviders,
        meta: {
          title: "Blusalt Admin - View Payment Providers in the system",
        },
      },
      {
        path: "/payment-providers/:id",
        name: "PaymentProviderDetail",
        component: PaymentProviderDetail,
        meta: {
          title: "Blusalt Admin - View Payment Providers in the system",
        },
      },
      {
        path: "/customer-funds-transfer-transactions/:id",
        name: "CustomerTransactions",
        component: CustomerTransactions,
        meta: {
          title: "Blusalt Admin - View FT Customer Transactions in the system",
        },
      },
      {
        path: "/invites",
        name: "Invites",
        component: Invites,
        meta: {
          title:
            "Blusalt Admin - View all the invited customers within the system",
        },
      },
      {
        path: "/customers",
        name: "Customers",
        component: Customers,
        meta: {
          title: "Blusalt Admin - View all the Customers within the system",
        },
      },
      {
        path: "/payment-merchants",
        name: "Payment Merchants",
        component: PaymentMerchants,
        meta: {
          title: "Blusalt Admin - View all the payment customers information",
        },
      },
      {
        path: "/create-customer",
        name: "CreateCustomer",
        component: CreateCustomer,
        meta: {
          title: "Blusalt Admin - Start adding customers into the system",
        },
      },
      {
        path: "/white-label",
        name: "white-label",
        component: WhiteLabelRequests,
        meta: {
          title:
            "Blusalt Admin - View all the white label requests within the system",
        },
      },
      {
        path: "/services",
        name: "Services",
        component: Services,
        meta: {
          title:
            "Blusalt Admin - View the listing of all the services within the application",
        },
      },
      {
        path: "/service-providers",
        name: "ServiceProviders",
        component: ServiceProviders,
        meta: {
          title:
            "Blusalt Admin - View the listing of all the services and service providers within the application",
        },
      },
      {
        path: "/providers",
        name: "Providers",
        component: Providers,
        meta: {
          title:
            "Blusalt Admin - View the listing of all the services and Infrastructure providers within the application",
        },
      },
      {
        path: "/customer/:clientid",
        props: true,
        name: "CustomerDetail",
        component: CustomerDetail,
        meta: {
          title: "Blusalt Admin - View the details of a single customer",
        },
      },
      {
        path: "/customer/:clientid/team/:id",
        props: true,
        name: "TeamDetail",
        component: TeamDetail,
        meta: {
          title: "Blusalt Admin - View the details of a single customer",
        },
      },
      {
        path: "/payment-merchant/:clientid",
        props: true,
        name: "MerchantDetail",
        component: MerchantDetail,
        meta: {
          title: "Blusalt Admin - View the details of a single customer",
        },
      },
      {
        path: "/payment-merchant/verifications/:clientid",
        props: true,
        name: "VerificationsDetail",
        component: VerificationsDetail,
        meta: {
          title: "Blusalt Admin - View the details of a single customer",
        },
      },
      {
        path: "/payment-merchant/:clientid/customer/:customerid",
        props: true,
        name: "MerchantCustomerDetail",
        component: MerchantCustomerDetail,
        meta: {
          title: "Blusalt Admin - View the details of a single customer",
        },
      },
      {
        path: "/single-wallet/:clientid/:walletId/:ref",
        props: true,
        name: "SingleWallet",
        component: SingleWallet,
        meta: {
          title: "Blusalt Admin - View the details of a single wallet",
        },
      },
      {
        path: "/sub-account-details/:clientid/:subAccountId",
        props: true,
        name: "SingleSubAccount",
        component: SingleSubAccount,
        meta: {
          title: "Blusalt Admin - View the details of a single sub account",
        },
      },
      {
        path: "/payment-link-details/:clientid/:linkId",
        props: true,
        name: "SinglePaymentLink",
        component: SinglePaymentLink,
        meta: {
          title: "Blusalt Admin - View the details of a single payment link",
        },
      },
      {
        path: "/reserved-account-details/:clientid/:accountId",
        props: true,
        name: "SingleReservedAccount",
        component: SingleReservedAccount,
        meta: {
          title:
            "Blusalt Admin - View the details of a single reserved account",
        },
      },
      {
        path: "/invoice-details/:clientid/:invoiceId",
        props: true,
        name: "SingleInvoice",
        component: InvoiceDetail,
        meta: {
          title:
            "Blusalt Admin - View the details of a single reserved account",
        },
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title: "Blusalt Admin - Welcome to your dashboard",
        },
      },
      {
        path: "/transactions",
        name: "Transactions",
        component: Transactions,
      },
      {
        path: "/credit-logs",
        name: "CreditLogs Overview",
        component: CreditLogsOverview,
        meta: {
          title: "Blusalt Admin - View the credit log information",
        },
      },
      {
        path: "/credit-logs-listing",
        name: "CreditLogs",
        component: CreditLogs,
        meta: {
          title: "Blusalt Admin - View the credit log information",
        },
      },
      {
        path: "/personal-smart-id",
        name: "Personal SmartId",
        component: PersonalSmartID,
        meta: {
          title: "Blusalt Admin - View reports from all personal verification checks",
        },
      },
      {
        path: "/business-smart-id",
        name: "Business SmartId",
        component: BusinessSmartId,
        meta: {
          title: "Blusalt Admin - View reports from all personal verification checks",
        },
      },
      {
        path: "/verification-subscription",
        name: "Verification Subscription",
        component: VerificationSubscriptions,
        meta: {
          title: "Blusalt Admin - View reports from all verification Subscription",
        },
      },
      {
        path: "/verification-subscription/:planId",
        name: "Verification Subscription Details",
        component: VerificationSubscriptionDetails,
        meta: {
          title: "Blusalt Admin - View reports from all verification Subscription",
        },
      },
      {
        path: "/personal-smart-id/:smart_id",
        name: "Personal SmartId Details",
        component: PersonalSmartIdDetails,
        meta: {
          title: "Blusalt Admin - View reports from all personal verification checks",
        },
      },
      {
        path: "/business-smart-id/:smart_id",
        name: "Business SmartId Details",
        component: PersonalSmartIdDetails,
        meta: {
          title: "Blusalt Admin - View reports from all personal verification checks",
        },
      },
      {
        path: "/audit",
        name: "AuditTrails",
        component: AuditTrails,
        meta: {
          title: "Blusalt Admin - View audit trail",
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "/payments/disputes",
        name: "PaymentDisputes",
        component: PaymentDisputes,
      },
      {
        path: "/logs/sms",
        name: "Logs",
        component: SMSLog,
        meta: {
          title: "Blusalt - Logs",
        },
      },
      {
        path: "/logs/service-logs",
        name: "ServiceProviderLogs",
        component: ServiceProviderLogs,
        meta: {
          title: "Blusalt Admin - View the service provider logs",
        },
      },
      {
        path: "/logs/providers",
        name: "Providers",
        component: ProviderListing,
        meta: {
          title: "Blusalt Admin - View the service provider logs",
        },
      },
      {
        path: "/logs/providers-overview",
        name: "Providers",
        component: ProviderOverview,
        meta: {
          title: "Blusalt Admin - View the service provider logs",
        },
      },
      {
        path: "/logs/providers/:id",
        name: "Providers",
        component: ProviderDetails,
        meta: {
          title: "Blusalt Admin - View the service provider logs",
        },
      },
      {
        path: "/logs/bill-payment-logs",
        name: "BillPaymentLogs",
        component: BillPaymentLogs,
        meta: {
          title: "Blusalt Admin - View the service provider logs",
        },
      },
      {
        path: "/logs/verification-logs",
        name: "ServiceReportsLogs",
        component: ServiceReports,
        meta: {
          title: "Blusalt Admin - View the service verification logs",
        },
      },
      {
        path: "/logs/address-verification-logs",
        name: "AddressServiceReportsLogs",
        component: AddressVerificationReport,
        meta: {
          title: "Blusalt Admin - View the service verification logs",
        },
      },
      {
        path: "/logs/business-registration-logs",
        name: "BusinessRegistrationLogs",
        component: BusinessRegistrationLogs,
        meta: {
          title: "Blusalt Admin - View the business registration logs",
        },
      },
      {
        path: "/logs/business-registration-logs/:id",
        name: "BusinessRegistrationLogsDetails",
        component: BusinessRegistrationLogsDetails,
        meta: {
          title: "Blusalt Admin - View the business registration logs",
        },
      },
      {
        path: "/logs/bulk-verification-logs/:id",
        name: "BulkVerificationDetails",
        component: BulkVerificationDetails,
        meta: {
          title: "Blusalt Admin - View the service verification logs",
        },
      },
      {
        path: "/logs/verification-logs/:id",
        name: "Service Report Details",
        component: ServiceReportDetails,
        meta: {
          title: "Blusalt - Debit Transactions",
        },
        children: [
          {
            path: "ownership",
            name: "Ownership",
            component: Ownership,
            meta: {
              title: "Blusalt - Ownership",
            },
          },
          {
            path: "personnel",
            name: "Personnel",
            component: Personnel,
            meta: {
              title: "Blusalt - Personnel",
            },
          },
          {
            path: "company-information",
            name: "ContactInformation",
            component: ContactInformation,
            meta: {
              title: "Blusalt - Contact Information",
            },
          },
          {
            path: "main_information",
            name: "MainInformation",
            component: MainInformation,
            meta: {
              title: "Blusalt - Main formation",
            },
          },
          {
            path: "other_information",
            name: "OtherInformation",
            component: OtherInformation,
            meta: {
              title: "Blusalt - Other formation",
            },
          },
        ],
      },
    ],
    beforeEnter(to, from, next) {
      next();
    },
  },
];
