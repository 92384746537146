<template>
  <div class="col-lg-12 col-md-12">
    <div
      v-if="getUserRole === 'super_admin' || getUserRole === 'admin'"
      class="mb-4 d-flex justify-content-end"
    >
      <AddMemberModal @refetch="$refs.teamRef.refresh()" />
    </div>
    <div>
      <TableWrapper
        :loading="loading"
        :errorMsg="errorMsg"
        :refetch="fetchMembers"
        :isEmpty="isEmpty"
        :emptyTitle="emptyTitle"
      >
        <div class="w-100 py-3" slot="content">
          <v-server-table
            ref="teamRef"
            class="v-table row-pointer"
            :data="customers"
            :columns="columns"
            :options="options"
            @loaded="onLoaded"
            @row-click="handleClick"
          >
            <template slot="first_name" slot-scope="{ row }">
              {{ row.first_name || "Not Available" }}
            </template>
            <template slot="last_name" slot-scope="{ row }">
              {{ row.last_name || "Not Available" }}
            </template>
            <template slot="role" slot-scope="{ row }">
              {{ titleCase(row.role || "") }}
            </template>
            <div slot="status" slot-scope="{ row }">
              <span
                class="badge badge-app"
                :class="
                  row.status == 'active' ? 'badge-success' : 'badge-danger'
                "
              >
                {{ row.status == "active" ? "Active" : "Inactive" }}
              </span>
            </div>
          </v-server-table>
        </div>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import TableWrapper from "@/components/utils/TableWrapper/TableWrapper";
import { tableOptions, titleCase } from "../utils/helpers";
import { componentData } from "../../../public/assets/mixins/componentData";
import AddMemberModal from "./TeamMember/modals/AddMemberModal";

const tableHeadings = {
  email: "Email",
  first_name: "First Name",
  last_name: "Last Name",
  role: "Role",
  status: "Status",
};

export default {
  mixins: [componentData],
  components: {
    TableWrapper,
    AddMemberModal,
  },
  data() {
    return {
      emptyTitle: "No Customer Found",
      loading: false,
      showSideModal: false,
      errorMsg: "",
      customers: [],
      ...tableOptions(tableHeadings, 25),
    };
  },
  computed: {
    getUserRole() {
      return this.$store.state.account.profile.role;
    },
  },
  methods: {
    titleCase,
    handleClick({ row }) {
      this.$router.push(`/account/team/${row.id}`);
    },
    refetch(filters) {
      this.handleRefetch(filters, this.fetchCustomers, "teamRef");
    },
    async fetchMembers() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch("account/getTeam", {
          params,
        });

        const newData = response.data.data;
        const count = response.data.total;

        return {
          ...response,
          count,
          total: count,
          data: newData,
        };
      };
    },
  },
  created() {
    this.fetchMembers();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
