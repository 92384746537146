<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-primary mr-3"
      @click="openModal"
    >
      Add Member
    </button>
    <SideModal
      :show="show"
      :toggle="closeModal"
      :title="`Send Invite`"
      :subTitle="'Send an invite to a customer to create account'"
    >
      <form @submit.prevent="handleSubmit">
        <div>
          <CustomInput
            :name="'first_name'"
            v-model="request.first_name"
            :label="`Frst Name`"
            :placeholder="`First Name`"
            :required="true"
            :error="formErrors.first_name"
            :handleError="handleError"
          />
        </div>
        <div>
          <CustomInput
            :name="'last_name'"
            v-model="request.last_name"
            :label="`Last Name`"
            :placeholder="`Last Name`"
            :required="true"
            :error="formErrors.last_name"
            :handleError="handleError"
          />
        </div>
        <div>
          <CustomInput
            :name="'email'"
            v-model="request.email"
            :label="`Email`"
            :placeholder="`Email`"
            :required="true"
            type="email"
            :error="formErrors.email"
            :handleError="handleError"
          />
        </div>
        <div>
          <CustomInput
            :name="'phone_number'"
            :label="`Phone Number`"
            :placeholder="`Phone Number`"
            v-model="request.phone_number"
            :isNum="true"
            :required="true"
            :error="formErrors.phone_number"
            :handleError="handleError"
          />
        </div>

        <CustomSelect
          label="Select Role"
          name="role"
          placeholder="Select New Role"
          :optionLabel="'name'"
          :options="roles"
          v-model="request.role"
          :error="formErrors.role"
          :handleError="handleError"
        />
        <FormButtons
          :loading="loading"
          :handleCloseModal="closeModal"
          :closeBtnName="`Close`"
          :btnName="`Add Member`"
        />
      </form>
    </SideModal>
  </div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import FormButtons from "@/components/utils/FormButtons/FormButtons";
import CustomInput from "@/components/utils/CustomInput/CustomInput";
import CustomSelect from "@/components/utils/CustomSelect/CustomSelect";
import { formValidator } from "../../../utils/FormValidator/FormValidator";
import SideModal from "../../../utils/Modal/SideModal.vue";

const initialState = () => ({
  request: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    role: "",
  },
  show: false,
  loading: false,
  successMsg: false,
  formErrors: {},
});

export default {
  mixins: [notifications],
  data() {
    return {
      ...initialState(),
      roles: [
        { name: "Admin", value: "admin" },
        { name: "Operator", value: "operator" },
        { name: "Customer Care", value: "customer_care" },
      ],
    };
  },
  methods: {
    openModal() {
      this.show = true;
    },
    closeModal() {
      Object.assign(this.$data, initialState());
      this.show = false;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    handleSubmit(e) {
      e.preventDefault();
      const { formErrors, isValid } = formValidator(this.request);
      if (isValid) {
        this.addMember();
      } else {
        this.formErrors = formErrors;
      }
    },
    async addMember() {
      try {
        this.loading = true;
        let response = await this.$store.dispatch("account/addTeamMember", {
          firstName: this.request?.first_name,
          lastName: this.request?.last_name,
          email: this.request?.email,
          phoneNumber: this.request?.phone_number,
          role: this.request?.role?.value.toLowerCase(),
        });
        this.loading = false;
        this.closeModal();
        this.$emit("refetch");
        this.showSuccessNotification(response.message);
      } catch (error) {
        this.loading = false;
        this.showErrorNotification(error?.message);
      }
    },
  },
  components: {
    FormButtons,
    CustomInput,
    CustomSelect,
    SideModal,
  },
};
</script>
