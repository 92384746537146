<template>
	<div class="az-content-body">
		<div class="row">
			<div class="col-md-12" v-if="activeTab === 'services'">
				<div class="col-lg-12">
					<div class="SectionItem no-b-b mb-3">
						<div class="SectionHeader">
							<span class="title">Services and Service Providers</span>
							<div class="description">
								View all the Services offered within the system
								and the provider(s)
							</div>
						</div>
					</div>
				</div>

				<!-- <Loader v-if="isLoading == true"></Loader> -->
				<div v-if="serviceProviderParameters.length > 0" class="card pt-3">
					<v-client-table name="service-listing-table" class="v-table" :data="serviceProviderParameters"
						:columns="columns" :options="serviceOptions">
						<div slot="id" slot-scope="props" :href="props.id" class="d-flex w-100 justify-content-start">
							<span v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'" v-tooltip.top-center="'Edit Priority'"
								class="pointer mr-4" data-toggle="modal" :data-target="
									props.row.providers.length
										? '#editPriority'
										: ' '
								" @click="edit(props.row, props.index)">
								<button type="submit" class="btn btn-sm btn-outline-primary btn-block">
									<span>
										<i class="typcn typcn-pencil mr-1"></i>Edit Priority
									</span>
								</button>
							</span>
							<span v-tooltip.top-center="'View Parameters'" class="pointer ml-2"
								@click="view(props.row, props.index)">
								<button type="submit" class="btn btn-sm btn-primary btn-block">
									<span>
										<i class="typcn typcn-eye-outline mr-1"></i>View Parameters
									</span>
								</button>
							</span>
						</div>
					</v-client-table>
				</div>
				<DefaultContent v-else :image="'empty-resources.png'" :title="'No Service Data'" :subtitle="
						'There are currently no services in the system, do check back later.'
					"></DefaultContent>
			</div>

			<div class="col-md-12" v-if="activeTab === 'service_providers'">
				<div class="col-lg-12">
					<div class="SectionItem mb-0">
						<div class="SectionHeader">
							<span class="title">Provider Parameter(s) for
								{{ service_label }}</span>
							<div class="description">
								View Infrastructure Provider Parameter(s) for
								{{ service_label }} in the system
							</div>
						</div>
						<div class="content">
							<button class="btn btn-primary btn-block mb-3" @click="activeTab = 'services'">
								Go Back
							</button>
						</div>
					</div>
				</div>

				<div v-if="serviceProvider.length > 0" class="card">
					<div class="w-100 py-3">
						<v-client-table name="provider-listing-table" class="v-table" :data="serviceProvider"
							:columns="cols" :options="providerOptions">
							<div slot="ios_validation_license" slot-scope="props"
								:href="props.row.ios_validation_license" v-if="props.row.ios_validation_license">
								{{ props.row.ios_validation_license }}
							</div>
							<div slot="ios_validation_license" slot-scope="props"
								:href="props.row.ios_validation_license" v-else>
								N/A
							</div>
							<div slot="android_validation_license" slot-scope="props"
								:href="props.row.android_validation_license"
								v-if="props.row.android_validation_license">
								{{ props.row.android_validation_license }}
							</div>
							<div slot="android_validation_license" slot-scope="props"
								:href="props.row.android_validation_license" v-else>
								N/A
							</div>
							<div slot="id" slot-scope="props" :href="props.id" class="d-flex justify-content-start">
								<span v-tooltip.top-center="'Edit Parameters'" class="pointer" data-toggle="modal"
									data-target="#editParameters" @click="editParams(props.row)">
									<button type="submit" class="btn btn-sm btn-primary btn-block"
										v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
										<span>
											<i class="typcn typcn-pencil mr-1"></i>Edit Parameters
										</span>
									</button>
								</span>
							</div>
						</v-client-table>
					</div>
				</div>
				<DefaultContent v-else :image="'empty-resources.png'" :title="'No Service Provider Data'" :subtitle="
						'There are currently no services in the system, do check back later.'
					"></DefaultContent>
			</div>
		</div>

		<!-- edit parameters -->
		<AppEditParameters :id="'editParameters'" ref="editParameters" @editParameters="editParameters"
			:editing="editing" />

		<!-- edit priority -->
		<AppEditPriority v-if="editPriorityModal" :id="'editPriority'" :providers="providers" ref="editPriority"
			@editPriority="editPriority" :editing="editing" />
	</div>
</template>

<script>
import Nprogress from "nprogress";
import AppEditParameters from "../components/modals/EditParameters";
import AppEditPriority from "../components/modals/EditPriority";
import { notifications } from "../../public/assets/mixins/notifications";

export default {
	name: "Service-Providers",
	mixins: [notifications],
	components: {
		AppEditParameters,
		AppEditPriority,
	},
	data() {
		return {
			editPriorityModal: false,
			activeTab: "services",
			service_id: "",
			service_label: "",
			country_code: "",
			service_provider_id: "",
			providers: [],
			editing: false,
			submitting: false,
			isLoading: true,
			serviceProviderParameters: [],
			serviceProvider: [],
			columns: ["serial_no", "service_label", "country_code", "id"],
			cols: [
				"serial_no",
				"provider_name",
				"http_method",
				"ios_validation_license",
				"android_validation_license",
				"price",
				"priority",
				// "country_code",
				"id",
			],
			serviceOptions: {
				sortable: [],
				perPage: 10,
				pagination: {
					chunk: 5,
				},
				texts: {
					filter: "",
					filterPlaceholder: "Search..",
					noResults: "No matching services",
				},
				headings: {
					serial_no: "S/N",
					service_label: "Service Name",
					service_label: "Country Code",
					id: "Actions",
				},
			},
			providerOptions: {
				sortable: [],
				perPage: 10,
				pagination: {
					chunk: 5,
				},
				texts: {
					filter: "",
					filterPlaceholder: "Search..",
					noResults: "No matching Providers",
				},
				headings: {
					serial_no: "S/N",
					id: "Actions",
					http_method: "HTTP Method",
					ios_validation_license: "iOS Validation License",
					android_validation_license: "Android Validation License",
					price: "Price",
					priority: "Priority",
				},
			},
		};
	},

	async mounted() {
		await this.getProviders();
	},

	computed: {
		getMetaData() {
			return this.$store.state.metaData.services;
		},
		getApiServices() {
			return this.$store.state.metaData.apiServiceCategories;
		},
		getUserRole() {
			return this.$store.state.account.profile.roles[0].role_label
		}
	},

	methods: {
		filteredServices() {
			console.log("[ this.getMetaData ] ->", this.getMetaData);
			this.getMetaData;
		},

		async getProviders() {
			try {
				let arr;
				await this.$store
					.dispatch("serviceProviders/getServiceProviderParameters")
					.then((res) => {
						console.log("[parameters] ->", res);
						let n = 1;
						let x = 1;
						const serviceProviderParameters = res.result.map(
							(parameter) => {
								return {
									serial_no: n++,
									id: parameter.id,
									service_label: parameter.service_label,
									country_code: parameter.country_code,
									providers: (arr = parameter.providers.map(
										(provider) => {
											return {
												serial_no: x++,
												id: provider.id,
												provider_name:
													provider.provider_name,
												route: provider.pivot.route,
												http_method:
													provider.pivot.http_method,
												ios_validation_license:
													provider.pivot
														.ios_validation_license,
												android_validation_license:
													provider.pivot
														.android_validation_license,
												price: provider.pivot.price,
												priority:
													provider.pivot.priority,
											};
										}
									)),
								};
							}
						);
						this.serviceProviderParameters = [
							...serviceProviderParameters,
						];
						this.isLoading = false;
						Nprogress.done();
					});
			} catch (error) {
				Nprogress.done();
				this.isLoading = false;
				this.serviceProviderParameters = [];
			}
		},

		dismissModal(id) {
			document.getElementById(id).click();
		},

		view(serviceInfo) {
			let id = serviceInfo.id;
			this.service_id = id;
			let label = serviceInfo.service_label;
			this.service_label = label;
			let providers = serviceInfo.providers;
			this.serviceProvider = providers;
			this.activeTab = "service_providers";
		},

		async editParams(parameterInfo) {
			await this.$store.dispatch(
				"serviceProviders/setSingleParameter",
				parameterInfo
			);
			this.service_provider_id = parameterInfo.id;
		},

		async edit(serviceInfo) {
			const providers = serviceInfo.providers.map((provider) => {
				return {
					service_label: serviceInfo.service_label,
					service_id: serviceInfo.id,
					provider_id: provider.id,
					provider_name: provider.provider_name,
					priority: provider.priority,
				};
			});
			if (providers.length) {
				this.editPriorityModal = true;
				this.providers = [...providers];
			} else {
				this.showErrorNotification("No providers available.");
			}
		},

		editParameters(data) {
			this.editing = true;
			this.$store
				.dispatch("serviceProviders/editServiceProviderParameters", {
					service_id: this.service_id,
					provider_id: this.service_provider_id,
					data: data,
				})
				.then((apiResponse) => {
					this.getProviders();
					this.editing = false;
					this.dismissModal("editParameters");
					this.showSuccessNotification(apiResponse.message);
				})
				.catch((err) => {
					this.editing = false;
					console.log("err>>>", err);
					this.dismissModal("editParameters");
					this.showErrorNotification(err.message);
				});
		},

		editPriority(data) {
			this.editing = true;
			this.$store
				.dispatch("serviceProviders/editServiceProviderPriority", data)
				.then((apiResponse) => {
					this.getProviders();
					this.editing = false;
					this.editPriorityModal = false;
					this.dismissModal("editPriority");
					this.showSuccessNotification(apiResponse.message);
				})
				.catch((err) => {
					this.editing = false;
					this.editPriorityModal = false;
					console.log("err>>>", err);
					this.dismissModal("editPriority");
					this.showErrorNotification(err.message);
				});
		},
	},
};
</script>

<style scoped>
.app-key-wrap {
	padding-bottom: 20px;
}

.app-key-wrap .app-key-item-wrapper {
	border-bottom: 2px dotted #b0c1dd;
	border-radius: 3px;
	margin-bottom: 10px;
	box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.03);
}

.service-name {
	display: flex;
	place-items: center;
	font-size: 18px;
	padding-left: 20px;
	width: 100%;
	height: 100%;
}
.service-icon {
	font-size: 20px;
}
.service-list {
	width: 100%;
	flex: wrap;
}

.service-content {
	box-shadow: inset -1px 3px 7px #ededed;
	padding: 20px;
	padding: 25px 35px;
}

.service-listing {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}
.service-listing.header {
	font-size: 17px;
	border-bottom: 1px solid #e7e5e5;
	padding-inline-start: 0;
}

.service-listing li {
	padding-bottom: 10px;
	list-style: none;
}
.service-listing:not(:first-child),
.service-listing:not(:last-child) {
	font-size: 16px;
	padding-bottom: 10px;
	border-bottom: 1px solid #e7e5e5;
}
td:nth-child(3) > div,
td:nth-child(4) > div {
	width: 250px;
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>
