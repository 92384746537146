<template>
	<div>
		<div v-if="role === 'Blusalt Admin'">
			<AddTeamModal />
		</div>
		<TableWrapper :loading="loading" :errorMsg="errorMessage" :refetch="refetch" :isEmpty="isEmpty"
			:emptyTitle="'No Team Found'">
			<div slot="content" class="p-4">
				<v-client-table class="v-table row-pointer" :data="teams" :columns="columns" :options="options"
					@row-click="handleClick">
					<template slot="user.firstname" slot-scope="{ row }">
						{{ row.user.firstname }}
						{{ row.user.lastname }}
					</template>
					<template slot="user.phone_number" slot-scope="{ row }">
						{{ getPhone(row.user.phone_number) }}
					</template>
					<template slot="role" slot-scope="{ row }">
						<span v-if="!row.role"> N/A</span>
						<span v-else>{{ row.role.name }}</span>
					</template>
					<template slot="user.is_active" slot-scope="{ row }">
						<span>
							<span v-if="row.user.is_active === 1" class="text-success">Active</span>
							<span v-else class="text-primary">Inactive</span>
						</span>
					</template>
				</v-client-table>
			</div>
		</TableWrapper>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins";
import AddTeamModal from './modals/AddTeamModal.vue'

const tableHeadings = {
	"user.email": "Email",
	"user.firstname": "Name",
	"user.phone_number": "Phone Number",
	"role": "Role",
	"user.is_active": "Status",
};

export default {
	name: "Teams",
	mixins: [componentData],
	data() {
		return { ...this.tableOptions(tableHeadings, 20) };
	},
	props: ["customer", "loading", "refetch", "role"],
	methods: {
		handleClick({ row }) {
			this.$router.push(
				`/customer/${this.$route.params.clientid}/team/${row.id}`
			);
		},
	},
	components: {
		AddTeamModal,
	},
	computed: {
		isEmpty() {
			this.customer.teamMembers && this.customer.teamMembers.length === 0;
		},
		teams() {
			const customer = this.customer;
			if (customer.teamMembers && customer.teamMembers.length > 0)
				return customer.teamMembers;

			return [];
		},
		errorMessage() {
			const error =
				this.customer && this.customer.teamMembers
					? undefined
					: "Error Fetching Customer Team Members";

			return error;
		},
	},
	async mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
