<template>
	<div class="p-md-4 p-3">
		<CreateCustomerModal :show="showSideModal" :toggle="toggleSideModal" :refetch="refetch" />
		<div class="SectionItem no-b-b mb-3">
			<div>
				<p class="title m-0">Customers</p>
				<p class="text-left description">
					View all your payment customers information
				</p>
			</div>
		</div>

		<div class="px-3">
			<FilterWrapper>
				<template slot="pageSelect">
					<div v-if="getUserRole === 'Blusalt Admin' || getUserRole === 'super_admin' || getUserRole === 'admin'">
						<router-link to="/create-customer" class="btn btn-lg btn-primary">Create Customer</router-link>
						<!-- <button @click="toggleSideModal" class="btn btn-lg btn-primary">
              <span><img src="/assets/img/icons/profile-add.svg" alt=""/></span>
              Create Customer
            </button> -->
					</div>
				</template>
				<template slot="filterForm">
					<CustomersFilter :loading="loading" :rLoading="rLoading" :submitFilter="refetch"
						:clearFilter="() => clearFilter('customerRef')" />
				</template>
			</FilterWrapper>
		</div>

		<TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchCustomers" :isEmpty="isEmpty"
			:emptyTitle="emptyTitle">
			<div class="w-100 py-3" slot="content">
				<v-server-table ref="customerRef" class="v-table row-pointer" :data="customers" :columns="columns"
					:options="options" @loaded="onLoaded" @row-click="handleClick">
					<template slot="user.firstname" slot-scope="{ row }">
						<p class="mb-0 font-weight-bold">
							{{ row.user.firstname }}
							{{ row.user.lastname }}
						</p>
						{{ row.client_id }}
					</template>
					<template slot="user.type" slot-scope="{ row }">
						{{ row.user.roles[0].role_label }}
					</template>
					<div slot="is_active" slot-scope="{ row }">
						<span class="badge badge-app" :class="
								row.is_active == 1
									? 'badge-success'
									: 'badge-danger'
							">
							{{ row.is_active == 1 ? "Active" : "Inactive" }}
						</span>
					</div>
				</v-server-table>
			</div>
		</TableWrapper>
	</div>
</template>

<script>
import FailedState from "../../components/utils/ResponseState/FailedState";
import { tableOptions } from "../../components/utils/helpers";
import CustomersFilter from "../../components/filters/CustomersFilter";
import { componentData } from "../../../public/assets/mixins/componentData";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import CreateCustomerModal from "./modals/CreateCustomerModal.vue";

const tableHeadings = {
	"user.firstname": "Full Name",
	"user.email": "Email",
	"user.type": "Customer Type",
	is_active: "Status",
	organization_name: "Organization",
	created_at: "Date Created",
};

export default {
	name: "Customer",
	mixins: [componentData],
	data() {
		return {
			emptyTitle: "No Customer Found",
			loading: false,
			showSideModal: false,
			errorMsg: "",
			customers: [],
			...tableOptions(tableHeadings, 25),
		};
	},
	components: {
		FailedState,
		CreateCustomerModal,
		CustomersFilter,
		TableWrapper,
	},
	computed: {
		getUserRole() {
			return this.$store.state.account.profile.roles[0].role_label
		}
	},
	methods: {
		toggleSideModal() {
			this.showSideModal = !this.showSideModal;
		},
		handleClick({ row }) {
			this.$router.push(`/customer/${row.client_id}`);
		},
		refetch(filters) {
			this.handleRefetch(filters, this.fetchCustomers, "customerRef");
		},
		async fetchCustomers() {
			this.options.requestFunction = async (data) => {
				const params = { ...data, ...this.filters };
				const response = await this.$store.dispatch(
					"customer/getCustomers",
					{
						params,
					}
				);

				const newData = response.data.data;
				const count = response.data.count;
				return { ...response, count, total: count, data: newData };
			};
		},
	},
	created() {
		this.fetchCustomers();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
