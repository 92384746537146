<template>
  <div class="az-content-body" v-if="teamInformation">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title mr-4">
              <a @click="$router.go(-1)" class="nav-sub-link"
                ><img
                  src="/assets/img/icons/go-back.svg"
                  alt="image"
                  height="30"
              /></a>
              User Information</span
            >
          </div>
          <div v-if="getUserRole === 'super_admin' || getUserRole === 'admin'">
            <UpdateMemberRoleModal
              :teamInformation="teamInformation"
              :refetch="fetchTeamDetails"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 card p-4">
        <h1 class="merchant-title">
          <span>
            <img
              height="35"
              src="/assets/img/avatar1.svg"
              alt=""
              class="mr-4"
            />
            <span>User Information</span>
          </span>
        </h1>
        <div class="contact-details--card">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">First Name</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ teamInformation?.first_name || "Not Available" }}
                </div>
              </span>
            </div>
          </div>
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Last Name</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ teamInformation?.last_name || "Not Available" }}
                </div>
              </span>
            </div>
          </div>

          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Email Address</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ teamInformation?.email || "Not Available" }}
                </div>
              </span>
            </div>
          </div>

          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Status</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  <span
                    class="badge badge-app"
                    :class="
                      teamInformation?.status == 'active'
                        ? 'badge-success'
                        : 'badge-danger'
                    "
                  >
                    {{
                      teamInformation?.status == "active"
                        ? "Active"
                        : "Inactive"
                    }}
                  </span>
                </div>
              </span>
            </div>
          </div>

          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Role</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  <span v-if="!teamInformation?.role"> N/A</span>
                  <span v-else>{{ titleCase(teamInformation?.role) }}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div
      v-if="getUserRole === 'super_admin' || getUserRole === 'admin'"
      class="my-4 d-flex flex__between"
    >
      <div>
        <h4>User Status</h4>
        <p>Make customer {{ action.toLowerCase() }}</p>
      </div>
      <div>
        <MakeMemberInactiveModal
          :teamInformation="teamInformation"
          :refetch="fetchTeamDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MakeMemberInactiveModal from "../components/account/TeamMember/modals/MakeMemberInactiveModal";
import UpdateMemberRoleModal from "../components/account/TeamMember/modals/UpdateMemberRoleModal";
import { titleCase } from "../components/utils/helpers";

export default {
  props: {},
  data() {
    return {
      teamInformation: null,
    };
  },
  methods: {
    titleCase,
    async fetchTeamDetails() {
      let response = await this.$store.dispatch(
        "account/getTeamMember",
        this.$route.params.id
      );
      this.teamInformation = response.data;
    },
  },
  computed: {
    action() {
      return this.teamInformation?.status === "active" ? "Inactive" : "Active";
    },
    getUserRole() {
      return this.$store.state.account.profile.role;
    },
  },
  components: {
    MakeMemberInactiveModal,
    UpdateMemberRoleModal,
  },
  async mounted() {
    await this.fetchTeamDetails();
  },
};
</script>

<style scoped>
.contact-details--card {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.contact-details--card-row {
  flex-grow: 1;
}

.contact-details--card-field {
  width: 100%;
}

.merchant-title {
  font-size: 24px;
}
</style>
