<template>
	<div class="col-md-12 col-lg-12 col-xl-12">
		<div class="payment-details--wrapper no-flex" v-if="false">
			<div class="payment-details--info">
				<h2 class="payment-section-hdr">Payment methods</h2>
				<div class="text-span">
					Please enter your preferred payment method below. You can
					use a credit / debit card, bank account or prepay through
					PayPal.
				</div>
			</div>
			<nav class="nav az-nav-line nav-line-user-account mg-t-35" style="top: -1px;">
				<a href data-toggle="tab" class="nav-link active show">Credit / Debit Card</a>
				<a href data-toggle="tab" class="nav-link">Bank Account</a>
				<a href data-toggle="tab" class="nav-link">PayPal</a>
			</nav>
			<div class="payment-details-box">
				<form method>
					<p class="text-span">
						Your card will not be charged until you start using paid
						services on blusalt.
					</p>
					<div class="gaps-2x"></div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label class="input-item-label">Card Number</label>
								<input id type="text" placeholder v-model="cardNumber"
									class="form-control input-bordered card-number" :class="{
										mastercard: cardNumber.startsWith('5'),
										visa: cardNumber.startsWith('4'),
									}" />
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label class="input-item-label">Date of Expiry</label>
								<input type="text" placeholder="MM/YY" class="form-control input-bordered" />
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label class="input-item-label">
									CVC / CVV
									<span class="card-hint">
										<svg width="14" height="14" viewBox="0 0 20 20" aria-hidden="true">
											<title />
											<g id="Layer_4">
												<path
													d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z" />
												<circle cx="10" cy="15" r="1" />
												<path
													d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z" />
											</g>
										</svg>
									</span>
								</label>
								<input type="text" placeholder class="form-control input-bordered" />
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<label class="input-item-label">Cardholder Name</label>
							<input type="text" placeholder class="form-control input-bordered" />
						</div>
					</div>
					<div class="gaps-3x"></div>
					<div class="row">
						<div class="col-md-12">
							<button type="button" class="btn btn-primary btn-block" disabled>
								<i class="la la-lock"></i> Save Card
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<TableWrapper :loading="false" :isEmpty="isServiceEmpty" :emptyTitle="'No Service Available'" :emptyText="
				'The list of services added for this customer will be listed here'
			">
			<div slot="content" class="payment-details--wrapper mb-0 no-flex">
				<div class="mg-b-15 d-flex border-bottom pb-3">
					<div class="service-header-title" style="width:50%;">
						Services
					</div>
					<div class="service-header-title d-flex" style="width:50%;">
						<div style="width:33.3%;">Has Fail Over</div>
						<div style="width:33.3%;">Price Per Call</div>
						<div style="width:33.3%;">Action</div>
					</div>
				</div>

				<div v-for="(service, key) in customer.customerServices" :key="key">
					<div class="row" v-if="key !== 0">
						<div class="col-md-12">
							<hr />
						</div>
					</div>
					<div class="d-flex">
						<div class style="width:50%;">
							<h2 class="payment-section-span">
								{{ service.service_label }}
								<!-- <span class="badge-inverted ml-2 text-uppercase">{{customer.client_id}}</span> -->
							</h2>
						</div>
						<div class="align-items-center d-flex" style="width:50%;">
							<div style="width:33.3%;">
								<h3 class="payment-section-span" v-text="
										service.pivot.has_fail_over == 0
											? 'No'
											: 'Yes'
									"></h3>
							</div>
							<div style="width:33.3%;">
								<h3 class="payment-section-span">
									{{ service.pivot.price_per_call }}
								</h3>
							</div>
							<div style="width:33.3%;">
								<a v-if="role === 'Blusalt Admin'" href @click="updatePrice(service)"
									data-toggle="modal" data-effect="effect-scale" data-backdrop="static"
									data-target="#applicationPriceModal" class="btn btn-lg btn-primary">Update Price</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</TableWrapper>

		<!-- Modal section -->
		<ApplicationPriceModal v-if="modalActive" @closePriceModal="closePriceModal" :customerData="modalData">
		</ApplicationPriceModal>
		<AddCreditModal :modalValue="modalValue" @refetchCustomer="fetchCustomer"></AddCreditModal>
	</div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins";
import AddCreditModal from "@/components/modals/AddCreditModal";
import ApplicationPriceModal from "@/components/modals/ApplicationPriceModal";

export default {
	mixins: [componentData],
	components: {
		AddCreditModal,
		ApplicationPriceModal,
	},
	props: {
		customer: {
			type: Object,
		},
		role: {
			type: String,
		},
	},
	data() {
		return {
			cardNumber: "",
			modalValue: {},
			modalActive: false,
			modalData: {},

			creditLogs: [],
			isProcessing: true,
			cardNumber: "",
			columns: [
				"serial_no",
				"amount_credited",
				"loadedBy.firstname",
				"payment_date",
			],
			options: {
				sortable: [],
				perPage: 25,
				pagination: {
					chunk: 5,
				},
				texts: {
					filter: "",
					filterPlaceholder: "Search..",
					noResults: "No matching records",
				},
				headings: {
					serial_no: "S/N",
					amount_credited: "Amount Credited",
					"loadedBy.firstname": "loaded By",
					payment_date: "Payment Date",
				},
			},
		};
	},
	computed: {
		isServiceEmpty() {
			const empty =
				this.customer.customerServices &&
				this.customer.customerServices.length > 0
					? false
					: true;
			return empty;
		},
	},
	methods: {
		closePriceModal() {
			this.modalActive = false;
			this.fetchCustomer();
		},
		updatePrice(service) {
			let customerData = {
				client_id: this.customer.client_id,
				service_id: service.id,
				service_label: service.service_label,
				charge_type: service.charge_type,
				price_per_call: service.pivot.price_per_call,
				has_fail_over: service.pivot.has_fail_over == 1 ? "Yes" : "No",
			};
			this.modalActive = true;
			this.modalData = customerData;
		},
		populateModal() {
			this.modalValue = this.customer;
		},
		async fetchLogs() {
			let verificationData = {
				clientID: this.customer.client_id,
			};
			try {
				let apiResponse = await this.$store.dispatch(
					"customer/customerCreditHistory",
					verificationData
				);
				console.log("[] ->", apiResponse.results);
				let n = 1;
				let creditLogs = apiResponse.results.credit_history.map(
					(creditLog) => {
						return {
							serial_no: n++,
							amount_credited: creditLog.amount_credited,
							loadedBy: creditLog.loadedBy,
							payment_date: creditLog.payment_date,
						};
					}
				);
				this.creditLogs = [...creditLogs];
				this.isProcessing = false;
			} catch (error) {
				console.log("[errors getting applications] ->", error);
				this.isProcessing = false;
			}
		},
		fetchCustomer() {
			this.$emit("fetchCustomer");
		},
	},
	async mounted() {
		await Promise.all([
			this.fetchLogs(),
			// make call to get charge type here
			this.$store.dispatch("services/getChargeTypes"),
		]);
	},
};
</script>

<style scoped>
.service-header-title {
	color: #5a5e63;
	font-size: 16px;
	font-weight: 600;
	text-transform: none;
	letter-spacing: 0;
}

.api-service-header {
	padding-top: 16px;
	padding-bottom: 15px;
	width: 80%;
	display: inline-block;
	line-height: 1.5;
}
.api-service-header h1 {
	margin-bottom: 3px;
	font-weight: 400;
	font-size: 1.3rem;
	color: #353f45;
	-webkit-font-smoothing: antialiased;
}
</style>
