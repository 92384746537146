import { apiClient } from "./api";

export default {
  userAccounts() {
    return apiClient.get(`Account/ListUserProfiles`);
  },
  getDashboardAnalytics() {
    return apiClient.get(`Authorize/Admin/Dashboard`);
  },
  verifyCustomer(verificationData) {
    let headers = {
      clientid: verificationData.clientID,
    };
    return apiClient.put(`Authorize/Admin/VerifyCustomer`, verificationData, {
      headers,
    });
  },
  getCurrentUser() {
    return apiClient.get("Authorize/CurrentAdminUser");
  },
  getProfile() {
    return apiClient.get(`Authorize/AdminProfile`);
  },
  adminWLRegistration(credentials) {
    return apiClient.get(`Authorize/AdminWLRegistration/${credentials}`);
  },
  editProfile(profileDetails) {
    return apiClient.put(`Authorize/EditProfile`, profileDetails);
  },
  AdminEditSecondaryCustomerProfile(profileDetails) {
    return apiClient.put(
      `/Authorize/AdminEditSecondaryCustomer/${profileDetails.client_id}`,
      profileDetails.formattedResponse
    );
  },
  passwordChange(credential) {
    return apiClient.post(`Authorize/ResetPassword`);
  },
  getTeam(params) {
    return apiClient.get(`Authorize/FetchAdminUsers`, {
      params,
    });
  },
  getTeamMember(id) {
    return apiClient.get(`Authorize/FetchAdminUser/${id}`);
  },
  addTeamMember(payload) {
    return apiClient.post(`Authorize/CreateAdminUser`, payload);
  },
  updateTeamMember(payload) {
    return apiClient.patch(`Authorize/UpdateAdminUserRole`, payload);
  },
  updateTeamMemberStatus(payload) {
    return apiClient.patch(`Authorize/UpdateAdminUserStatus`, payload);
  },
};
