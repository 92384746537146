<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-primary mr-3"
      @click="openAddProviderModal"
    >
      Update Role
    </button>
    <Modal
      id="updateRole"
      title="Update Role"
      :toggle="closeModal"
      :loading="loading"
    >
      <form @submit.prevent="updateRole">
        <CustomSelect
          name="role"
          placeholder="Select New Role"
          :optionLabel="'name'"
          :options="roles"
          v-model="role"
        />
        <FormButtons
          :loading="loading"
          :handleCloseModal="closeModal"
          :closeBtnName="`Close`"
          :btnName="`Update Role`"
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "@/components/utils/Modal/Modal";
import FormButtons from "@/components/utils/FormButtons/FormButtons";
import CustomInput from "@/components/utils/CustomInput/CustomInput";
import CustomSelect from "@/components/utils/CustomSelect/CustomSelect";

export default {
  mixins: [notifications],
  data() {
    return {
      loading: false,
      role: "",
      roles: [
        { name: "Admin", value: "admin" },
        { name: "Operator", value: "operator" },
        { name: "Customer Care", value: "customer_care" },
      ],
    };
  },
  props: ["teamInformation", "refetch"],
  methods: {
    openAddProviderModal() {
      $("#updateRole").modal("show");
    },
    closeModal() {
      $("#updateRole").modal("hide");
    },
    async updateRole() {
      if (this.role) {
        try {
          this.loading = true;
          let response = await this.$store.dispatch(
            "account/updateTeamMember",
            {
              userId: this.teamInformation.id,
              role: this.role.value.toLowerCase(),
            }
          );
          this.loading = false;
          this.closeModal();
          this.refetch();
          this.showSuccessNotification(response.message);
        } catch (error) {
          this.loading = false;
          this.showErrorNotification(error?.message);
        }
      }
    },
  },
  components: {
    Modal,
    FormButtons,
    CustomInput,
    CustomSelect,
  },
};
</script>
